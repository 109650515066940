import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGA } from '../../context/ga'

export default function MemberShipForm({ validators, code }){
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [isMarried, setIsMarried] = useState(false)
    const [haveKids, setHaveKids] = useState(false)
    const { trackLeadGenerated } = useGA()
    const [city, setCity] = useState('')
    const [district, setDistrict] = useState('')
    const [qualification, setQualification] = useState('')
    const [occupation, setOccupation] = useState('')
    const [hearAboutUs, setHearAboutUs] = useState('')

    const selectingMartialStatus = event =>{
        if (event.currentTarget.value === 'Married') setIsMarried(true)
        else if (isMarried) setIsMarried(false)
    }

    const selectHavingKids = event =>{
        if (event.currentTarget.value === 'Yes') setHaveKids(true)
        else if (haveKids) setHaveKids(false)
    }

    return (<>
        <p style={{color: 'red', width: "70%", textAlign: "left"}}>{!name || !phone || !email || !city || !district || !qualification || !occupation || !hearAboutUs ? "*Please fill all the fields" : '' }</p>
        <div className='inputsFieldsAround'>
            <input type='hidden' name='code' value={code} className='refinput' />
            <label className='inputAround' aria-label="User name">
				<input
                    onChange={ (e)=> {
                        validators.name(e)
                        setName(e.target.value)
                    }}
                    maxLength="64"
                    minLength="3"
                    required
                    name="name"
                    type={ 'text' }
                    placeholder={ t('forms.contactUs.placeholders.name') }
                    className="refinput"
                />
			</label>
            <label className='dropdownAround' aria-label="Marital Status">
                <select onChange={ selectingMartialStatus } name='maritalStatus' required>
                    <option disabled selected>Marital Status</option>
                    <option value={'Single'}>Single</option>
                    <option value={'Married'}>Married</option>
                    <option value={'Divorced'}>Divorced</option>
                    <option value={'Widow/Widower'}>Widow/Widower</option>
                </select>
            </label>
		</div>
        <div className='inputsFieldsAround'>
            <label className='inputAround' aria-label="Mobile number">
                <span className='countryCode'>
                    <img src='/media/icons/egypt.svg' alt='+20'/>
                    +20
                </span>
				<input
                    onChange={ (e)=> {
                        validators.phone(e)
                        setPhone(e.target.value)
                    }}
                    maxLength="11"
                    minLength="10"
                    required
                    name="mobile"
                    type={ 'text' }
                    placeholder={ 'Mobile number' }
                    className="refinput mobile-number"
                />
			</label>
            <label className='inputAround email' aria-label="Email adrress">
                <input
                    onChange={ (e)=> {
                        validators.email(e)
                        setEmail(e.target.value)
                    }}
                    maxLength="64"
                    minLength="8"
                    required
                    name="email"
                    type={ "email" }
                    placeholder={ t('forms.contactUs.placeholders.email') }
                    className="refinput"
                />
            </label>
		</div>
        <div className='inputsFieldsAround'>
            <label className='inputAround' aria-label="Facebook or instgram">
                <input
                    onChange={ validators.link }
                    maxLength="256"
                    minLength="10"
                    required
                    name="socialMedia"
                    type={ 'url' }
                    placeholder={ 'Facebook or Instagram Link' }
                    className="refinput"
                />
            </label>
            <label className='inputAround' aria-label="Address">
				<input
                    onChange={ validators.address }
                    maxLength="256"
                    minLength="3"
                    required
                    name="address"
                    type={ 'text' }
                    placeholder={ 'Address' }
                    className="refinput"
                />
			</label>
		</div>
        <div className='inputsFieldsAround'>
            <label className='dropdownAround' aria-label="City">
                <select name='city' required onChange={ (e)=> setCity(e.target.value) }>
                    <option disabled selected>City</option>
                    <option value={'Cairo'}>Cairo</option>
                    <option value={'Alexandria'}>Alexandria</option>
                    <option value={'Giza'}>Giza</option>
                </select>
            </label>
            <label className='dropdownAround' aria-label="Districts">
                <select name='districts' required onChange={ (e)=> setDistrict(e.target.value) }>
                    <option disabled selected>Districts</option>
                    <option value={'1st Settlement'}>1st Settlement</option>
                    <option value={'3rd Settlement'}>3rd Settlement</option>
                    <option value={'5th Settlement'}>5th Settlement</option>
                    <option value={'Shorouk'}>Shorouk</option>
                    <option value={'Maadi'}>Maadi</option>
                    <option value={'Katameya'}>Katameya</option>
                    <option value={'Mokatam'}>Mokatam</option>
                    <option value={'Nasr City'}>Nasr City</option>
                    <option value={'Heliopolis'}>Heliopolis</option>
                </select>
            </label>
		</div>
        <div className='inputsFieldsAround'>
            <label className='dropdownAround' aria-label="Academic Qualification">
                <select name='academicQualification' required onChange={ (e)=> setQualification(e.target.value) }>
                    <option disabled selected>Academic Qualification</option>
                    <option value={'Bachelor'}>Bachelor</option>
                    <option value={'Post Graduate Studies'}>Post Graduate Studies</option>
                    <option value={'High School Diploma'}>High School Diploma</option>
                </select>
            </label>
            <label className='dropdownAround' aria-label="Occupation">
                <select name='occupation' required onChange={ (e)=> setOccupation(e.target.value) }>
                    <option disabled selected>Occupation</option>
                    <option value={'Senior level'}>Senior level</option>
                    <option value={'Manager'}>Manager</option>
                    <option value={'Other'}>Other</option>
                </select>
            </label>
		</div>
        <div className='inputsFieldsAround'>
            <label className='inputAround' aria-label="Company name">
				<input
                    onChange={ validators.company }
                    maxLength="256"
                    minLength="3"
                    required
                    name="companyName"
                    type={ 'text' }
                    placeholder={ 'Company name' }
                    className="refinput"
                />
			</label>
            <label className='dropdownAround' aria-label="How did you hear about Life Sports Club?">
                <select name='howDidYouHearAboutUs' required onChange={ (e)=> setHearAboutUs(e.target.value) }>
                    <option disabled selected>How did you hear about Life Sports Club?</option>
                    <option value={'Social Media'}>Social Media</option>
                    <option value={'Life Club Member'}>Life Club Member</option>
                    <option value={'Life Club Employee'}>Life Club Employee</option>
                    <option value={'Event'}>Event</option>
                    <option value={'Life Partnership'}>Life Partnership</option>
                </select>
            </label>
		</div>
        {
            isMarried &&
            <>
                <div className='inputsFieldsAround'>
                    <label className='inputAround' aria-label="Spouse Name">
                        <input
                            onChange={ validators.spouseName }
                            maxLength="64"
                            minLength="3"
                            required
                            name="spouseName"
                            type={ 'text' }
                            placeholder={ 'Spouse Name' }
                            className="refinput"
                        />
                    </label>
                    <label className='inputAround' aria-label="Mobile number">
                        <span className='countryCode'>
                            <img src='/media/icons/egypt.svg' alt='+20'/>
                            +20
                        </span>
                        <input
                            onChange={ validators.spousePhone }
                            maxLength="11"
                            minLength="10"
                            required
                            name="spouseMobile"
                            type={ 'text' }
                            placeholder={ 'Spouse Phone Number' }
                            className="refinput mobile-number"
                        />
                    </label>
                </div>
                <div className='inputsFieldsAround'>
                    <label className='inputAround email' aria-label="Email adrress">
                        <input
                            onChange={ validators.spouseEmail }
                            maxLength="64"
                            minLength="8"
                            required
                            name="spouseEmail"
                            type={ "email" }
                            placeholder={ 'Email of Spouse' }
                            className="refinput"
                        />
                    </label>
                    <label className='inputAround' aria-label="Facebook or instgram">
                        <input
                            onChange={ validators.spouseLink }
                            maxLength="256"
                            minLength="10"
                            required
                            name="spouseSocialMedia"
                            type={ 'url' }
                            placeholder={ 'Spouse SM link' }
                            className="refinput"
                        />
                    </label>
                </div>
                <div className='inputsFieldsAround'>
                    <label className='dropdownAround' aria-label="Have Kids?">
                        <select name='haveKids' required onChange={ selectHavingKids }>
                            <option disabled selected>Have Kids?</option>
                            <option value={'Yes'}>Yes</option>
                            <option value={'No'}>No</option>
                        </select>
                    </label>
                    { haveKids &&
                        <label className='dropdownAround' aria-label="No.of Kids">
                            <select name='numOfKids' required>
                                <option disabled selected value={'0'}>No.of Kids</option>
                                <option value={'1'}>1</option>
                                <option value={'2'}>2</option>
                                <option value={'3'}>3</option>
                                <option value={'4'}>4</option>
                                <option value={'5'}>5</option>
                            </select>
                        </label>
                    }
                </div>
            </>
        }
        <button
            type='submit'
            onClick={ (e)=> {
                try {
                    validators.all(e)
                    trackLeadGenerated(name, phone, email)
                } catch {}
            }}
            disabled={!name || !phone || !email || !city || !district || !qualification || !occupation || !hearAboutUs}
            style={{
                opacity: !name || !phone || !email || !city || !district || !qualification || !occupation || !hearAboutUs ? 0.5 : 1
            }}
            validator="code"
            className='codeFormButton'
        >Submit
        </button>
    </>)
}