export default function validator(parent,fields,button){
    parent = document.querySelector(`.${ parent.getAttribute('class') }`)
    switch (typeof fields){
        case 'string':
            var selector = parent.querySelector(fields)
            var label = selector.parentElement
            if (!selector.required && selector.value === ''){
                // this field is not required
            } else {
                if (selector.value.replaceAll(' ','').length > 0 || selector.value === ''){
                    if (label.classList.contains('wrong')) {
                        label.classList.remove('wrong')
                    }
                    if (parent.querySelector(button).getAttribute('type') === 'button'){
                        parent.querySelector(button).setAttribute('type','submit')
                    }
                } else { 
                    label.classList.add('wrong')
                    if (parent.querySelector(button).getAttribute('type') !=='button'){
                        parent.querySelector(button).setAttribute('type','button')
                    }
                }
                typeValidation(selector, label)
            }
        break;
        case 'object':
            fields.forEach(field=>{
                var selector = parent.querySelector(field)
                if (selector){
                    var label = selector.parentElement
                    if (selector.value.length <= 0) {
                        label.classList.add('wrong')
                    }
                    typeValidation(selector, label)
                }
            })
        break;
        default:break;
    }
}

const typeValidation = (selector, label) => {
    let regex = new RegExp()
    switch (selector.name){
        case 'email':
            regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            if (selector.value.length && !regex.test(selector.value))
                label.classList.add('wrong')
        break
        case 'mobile':
            regex = /^((01[0-2|5]\d{8})||(1[0-2|5]\d{8}))$/
            if (selector.value.length && !regex.test(selector.value))
                label.classList.add('wrong')
        break
        case 'socialMedia':
            regex = /^(https?:\/\/)?(www\.)?(facebook\.com|fb\.com|instagram\.com)\/[a-zA-Z0-9._-]+\/?$/i
            if (selector.value.length && !regex.test(selector.value))
                label.classList.add('wrong')
        break
        case 'spouseEmail':
            regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            if (selector.value.length && !regex.test(selector.value))
                label.classList.add('wrong')
        break
        case 'spouseMobile':
            regex = /^((01[0-2|5]\d{8})||(1[0-2|5]\d{8}))$/
            if (selector.value.length && !regex.test(selector.value))
                label.classList.add('wrong')
        break
        case 'spouseSocialMedia':
            regex = /^(https?:\/\/)?(www\.)?(facebook\.com|fb\.com|instagram\.com)\/[a-zA-Z0-9._-]+\/?$/i
            if (selector.value.length && !regex.test(selector.value))
                label.classList.add('wrong')
        break
        default: break
    }
}