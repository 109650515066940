import '../../styles/pages/player/style.css'
import AboveAll from '../../components/AboveAll'
import { useEffect, useState } from 'react'
import { http } from '../../framework/http'
import NotFound from '../../components/NotFound';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { useGA } from '../../context/ga';

export default function VideoPlayer(){
    let { id } = useParams();
    const [data,setData] = useState(null)
    const [stats,setStats] = useState(false)
    const history = useHistory()
    const { trackContentView } = useGA()

    useEffect(()=>{
        async function fetching(){
            try {
                const response = await http.get(`/api/player/${id}`)
                if (!response.data) history.push('/')
                else if (response.status === 200){
                    setData(response.data)
                    setStats(true)
                    trackContentView('Video', response.data.url)
                } else {
                    setData(null)
                    setStats(true)
                }
            } catch (error) {
                history.push('/')
                setData(null)
                setStats(true)
            }
        }
        fetching()
    },[stats, id, history, trackContentView])

    function goBack(e){
        history.goBack()
    }

	document.title = 'LSC | player'
	return (
		<div className="LifeSportClubVideoPlayer">
			<AboveAll/>
            {stats?
                (data == null)?
                <NotFound/>:
                <>
                    <img onClick={goBack} className='player-back-button' src='/media/icons/player-back.png' alt='back button'/>
                    <iframe
                        title='life sport club player'
                        src={data.url}
                        className={'videoPlayer'}
                        frameborder="0"
                        allow="accelerometer;
                            autoplay; 
                            clipboard-write; 
                            encrypted-media; 
                            gyroscope; 
                            picture-in-picture; 
                        web-share"
                        >
                    </iframe>
                </>:
                <Loading/>
            }
		</div>
	)
}