import React from 'react'
import '../../styles/components/gallery/style.css'
import VideoItem from '../Community/videoItem.component'
import { useGA } from '../../context/ga'

function Stance({ path, link, classes, alt, altPath }) {
    const { trackContentView } = useGA()
    return (
        <div className={ classes } onClick={()=> trackContentView('Image', alt)}>
            <img className='banner' src={ path } alt={ alt } onError={ (e)=> e.currentTarget.src = altPath }/>
        </div>
    )
}

export default function Gallery({ images, link, alt }) {

	return (
		<div section="album" className="albumSection section">
            <h2 className="blogAlbumSectionHeader waiting">Gallery</h2>
            <div className="albumGroup fully default">
                { images.map(item=> {
                    return (item.type === 'video')
                    ?<VideoItem
                        link={ `/player/${item.id}` }
                        title={ item.title }
                        key={ item.id }
                        path={ item.path }
                        classes={ 'videos waiting' }
                        altPath={ '/media/images/landing-album.jpg '}
                    />
                    :<Stance
                        link={ link }
                        alt={ alt }
                        altPath={ '/media/images/landing-album.jpg' }
                        key={ item.id }
                        path={ item.path }
                        classes={ 'album waiting' }
                    />
                }) }
            </div>
        </div>
	)
}