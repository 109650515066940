export default function AcademyHead({cover,name}){
    return(
        <div className="academy-head">
            <img className="academy-cover" src={cover} alt="academy cover" onError={e=> e.currentTarget.src = '/media/images/academies.jpg'}/>
            <div className="academy-name">
                <h2>{name.toUpperCase()}</h2>
                <h2>ACADEMY</h2>
            </div>
        </div>
    )
}