import React from 'react'
import '../../styles/components/notFound/style.css'

export default function NotFound(){
	document.title = 'LSC | Not Found'
	return (
		<div className='notFoundContainer'>
			<h2>COMING SOON</h2>
		</div>
	)
}