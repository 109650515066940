import React, { useEffect, useState } from 'react'
import '../../../styles/pages/community/event/style.css'
import { http } from '../../../framework/http'
import Loading from '../../../components/Loading'
import Footer from '../../../components/Footer'
import AboveAll from '../../../components/AboveAll'
import Gallery from '../../../components/Gallery'
import EventHead from '../../../components/Community/Event/head.component'
import EventBody from '../../../components/Community/Event/body.component'
import { useHistory } from 'react-router-dom'
import { useGA } from '../../../context/ga'

export default function Event(props) {
	document.title = `LSC | events`
	const [stats,setStats] = useState(false)
	const [results,setResults] = useState(null)
	const history = useHistory()
	const { trackContentView } = useGA();

	useEffect(()=>{
		if (!stats) http.get(`/api/collections/events/${props.match.params.id}`).then(res=>{
			if (res.data){
				if (res.data.status === 'succeed'){
					setResults(res.data)
					setStats(true)
					trackContentView('Event', res.data.event.title)
				} else history.push('/')
			} else history.push('/')
		})
	},[props.match.params.id, stats, history, trackContentView])
	if (stats) return (
		<div className="eventContainer">
			<AboveAll/>
			<EventHead cover={results.event.big_cover} behaviour={results.event.stretch} name={results.event.title} />
			<EventBody description={results.event.article}/>
			{
				(results.gallery && results.gallery.length > 0)
					?<Gallery images={results.gallery} link={`/events/${results.event.id}`} alt={results.event.title}/>
					:null
			}
			<Footer/>
		</div>
	)
	else return <Loading/>
}