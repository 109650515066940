import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { setSlider, slideTo } from '../../common/imageSlider'
import '../../styles/pages/academies/style.css'
import { http } from '../../framework/http'

import Loading from '../../components/Loading/index'
import AboveAll from '../../components/AboveAll'
import AcademiesGallery from '../../components/Academies/Gallery'
import LightFooter from '../../components/LightFooter'
import { useGA } from '../../context/ga'

export default function AllAcademies() {
	const [stats,setStats] = useState(false)
	const [results, setResults] = useState(null)
	const { trackContentView } = useGA();
	const history = useHistory()
	document.title = "LSC | all academies"
	const {t} = useTranslation()
	useEffect(()=>{
		http.get('/api/collections/academies').then(res=>{
			if (res.data){
				if (res.data.status === 'succeed'){
					setResults(res.data)
					setStats(true)
					setSlider('.imageSlider', '.imagesContainer', '.nextBtn', '.prevBtn', '.pointsContainer', '.sloganContainer', 5000)
					trackContentView('About', document.title)
				} else
					history.push('/')
			} else history.push('/')
		})
	},[history, trackContentView])
	function Card({sport,classes}){
		return(
			<Link className={classes} to={`/academies/${sport.name}`}>
				<h2 className='cardName'>{ sport.name.toUpperCase() }</h2>
				<p className='cardDescription'>
					{ sport.age.split('\n').map((line, i)=> <span key={i}> { i > 0 && <br/> }<span>{ line }</span> </span>) }
					<br/>
					{ sport.time.split('\n').map((line, i)=> <span key={i}> { i > 0 && <br/> }<span>{ line }</span> </span>) }
				</p>
				<a href={ sport.registerLink } rel="noreferrer" className='linkSect' onClick={e=> e.stopPropagation()}>{t('academies.all.registerButton')}</a>
			</Link>
		)
	}
	function SliderCollection({images}){
		let collection = images.split('#')
		if(collection.length >0 ){
			return (
				<div className='imageSlider'>
					<div className='imagesContainer'>
						{
							collection.map((path,index)=>{
								return <img alt="events" key={index} src={path} onError={e=>e.currentTarget.src='/media/images/academies.jpg'}/>
							})
						}
					</div>
				</div>
			)
		}
	}
	function PointsCollection({images}){
		let collection = images.split('#')
		if(collection.length >0 ){
			return (
				<div className='pointsContainer'>
					{
						collection.map((path,index)=>{
							return <span className={index === 1?'current':null} onClick={()=> slideTo(index + 1)} key={index}></span>
						})
					}
				</div>
			)
		}
	}
	function SloganCollection({slogans}){
		let collection = slogans.split('#')
		if(collection.length >0 ){
			return (
				<div className='sloganContainer'>
					<h2>{ collection[0].split('\n').map((line, i)=> <div key={i}> { i > 0 && <br/> }<span>{ line }</span> </div>) }</h2>
					{
						// collection.map((slogan,index)=>{
						// 	return <h2 key={index}>{slogan}</h2>
						// })
					}
				</div>
			)
		}
	}
	return (
		<div className="allAcademiesContainer">
			{stats ?
				<>
					<AboveAll mode={'light'}/>
					<div className='sliderWrapper'>
						<svg xmlns="http://www.w3.org/2000/svg" className='prevBtn' alt='previous' viewBox="0 0 27 18.08"><g><g><polygon points="27 4.58 13.5 18.08 0 4.58 4.58 0 13.5 8.92 22.42 0 27 4.58"/></g></g></svg>
						<SliderCollection images={results.slider} />
						<PointsCollection images={results.slider} />
						<SloganCollection slogans={results.sliderText}/>
						<svg xmlns="http://www.w3.org/2000/svg" className='nextBtn' alt='next' viewBox="0 0 27 18.08"><g><g><polygon points="27 4.58 13.5 18.08 0 4.58 4.58 0 13.5 8.92 22.42 0 27 4.58"/></g></g></svg>
					</div>
					<div id='academies'>
						<h1 key={'welcome'} className='academiesWelcome'>{t('academies.all.academiesSectionIntro')}</h1>
							{stats ?
								<div key={'sports'} className='sports'>
									{results.academies? results.academies.map(sport=>{return <Card key={sport.id} classes={'sportCard'} sport={sport}/>}):null}
								</div>
							: <Loading/>
							}
					</div>
					<div className='academiesAlbumSection'>
						<h2 className="academiesAlbumSectionHeader waiting">GALLERY</h2>
						<AcademiesGallery images={results.images} total={results.total} full={`/api/collections/academies/full`}/>
					</div>
					<LightFooter/>
				</>
				: <Loading/>
			}
		</div>
	)
}