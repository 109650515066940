import React from 'react'
import '../../styles/components/application/style.css'
import { Link } from 'react-router-dom'

export default function Rejected(){
	document.title = 'LSC | Application rejected'
	return (
		<div className='applicationContainer'>
			<h2>There was a problem with your submission. Please submit again.</h2>
			<div className='applicationOptions'>
				<Link to={'/'}>
					<button>Home</button>
				</Link>
				<Link to={'/membership'}>
					<button>Try again</button>
				</Link>
			</div>
		</div>
	)
}