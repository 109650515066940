import { useState,useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Item from './item.component'
import Loading from "../Loading"
import { http } from '../../framework/http'

export default function Blogs({previewData,total}){
    const orders = [0,1]
    const {t} = useTranslation()
    const [data,setData] = useState(null)
    const [mode,setMode] = useState('short')
    function requestData(){
		setMode('loading')
		http.get(`/api/community/blogs`).then(res=>{
			if (res.data.status === 'succeed'){
				setData(res.data.data)
                setMode('full')
			} else {
                setMode('short')
			}
		})
	}
    useEffect(()=>{},[mode])
    switch (mode){
        case 'short':
            return (
                <div section="blogs" className="blogSection section comm">
                    <h2 className="communitySectionHeader waiting" style={{padding:'0'}}>{t('community.blogsSectionIntro')}</h2>
                    <div className='blogCollection'>
                        {orders.map(order=>{
                            let item = previewData[order]
                            return (item !== undefined)
                            &&<Item link={`/blogs/${item.id}`} key={item.id} path={item.card} classes={'blog waiting shadow-box'} alt={'blog'} altPath={'/media/images/landing-album.jpg'} title={item.title}/>
                        })}
                    </div>
                    {
                        total>2?<button className="sectionExpand" onClick={()=>{requestData()}}>More Blogs</button>:null
                    }
                </div>
            )
        case 'full':
            return (
                <div section="blogs" className="blogSection section comm">
                    <h2 className="communitySectionHeader waiting" style={{padding:'0'}}>{t('community.blogsSectionIntro')}</h2>
                    <div className='blogCollection'>
                        {data.map(item=>{
                            return <Item link={`/blogs/${item.id}`} key={item.id} path={item.card} classes={'blog waiting shadow-box'} alt={'blog'} altPath={'/media/images/landing-album.jpg'} title={item.title}/>
                        })}
                    </div>
                </div>
            )
        case 'loading':
            return (
                <div section="blogs" className="blogSection section comm">
                    <Loading/>
                </div>
            )
        default:
            return (
                <div section="blogs" className="blogSection section comm">
                </div>
            )
    }
}