import BoardMembers from "./sections/board"
import Partners from "./sections/partners"
import OurStory from "./sections/story"
import OurVision from "./sections/vision"

export default function Sections(){
    return(
        <>
            <OurStory/>
            <OurVision/>
            <BoardMembers />
            <Partners />
        </>
    )
}