export const updateResolution = ()=>{
    if (!document.body.getAttribute('size')) document.body.setAttribute('size','')
    if (window.innerWidth > 900) document.body.setAttribute('size','big')
    else {
        if (window.innerWidth > 550) document.body.setAttribute('size','mid')
        else document.body.setAttribute('size','sml')
    }
}
export const updateAboutCover = ()=>{
    if (window.innerWidth - window.innerHeight < -100){
        if (document.querySelector('img.cover')?.classList.contains('landscape')){
            document.querySelector('img.cover').src = '/media/images/about916.jpg'
            document.querySelector('img.cover')?.classList.replace('landscape','portrait')
        }
    } else {
        if (document.querySelector('img.cover')?.classList.contains('portrait')){
            document.querySelector('img.cover').src = '/media/images/about169.jpg'
            document.querySelector('img.cover')?.classList.replace('portrait','landscape')
        }
    }
}