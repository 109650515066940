import { Link } from 'react-router-dom'
import { popUp } from '../../common/imagePopUp'
import { useGA } from '../../context/ga'

export default function Item({link,path,classes,iid,alt,altPath,title,type}){
    const { trackContentView } = useGA()

    if (classes.includes('notALink')) return (
        <div className={classes} onClick={()=>{
            popUp(title,iid)
            trackContentView('Image', title)
        }}>
            <img className='banner' src={path} alt={alt} onError={(e)=> e.currentTarget.src = altPath}/>
        </div>
    )
    else return (
        <Link to={link} className={classes}>
            {(typeof path === "object")
                ?<picture>
                    <source media="(max-width: 700px)" srcSet={path[1]} onError={(e)=> e.currentTarget.src = altPath}/>
                    <img className='banner' src={path[0]} alt={alt} onError={(e)=> e.currentTarget.src = altPath}/>
                </picture>
                :<img className='banner' src={path} alt={alt} onError={(e)=> e.currentTarget.src = altPath}/>
            }
            {!title?null:
            <div className='inCardText'>
                <h4>{
                    title.split('\n').map((line, i)=> <div key={i}> { i > 0 && <br/> }<span>{ line }</span> </div>)
                }</h4>
                <h5>Read more...</h5>
            </div>}
        </Link>
    )
}