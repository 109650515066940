import { useState,useEffect } from 'react'
import "../../styles/pages/community/style.css"
import Loading from "../Loading"
import { http } from '../../framework/http'
import Album from './album.component'

export default function Albums({album,total}){
    const [data,setData] = useState(null)
    const [mode,setMode] = useState('short')
    function requestAlbums(){
		setMode('loading')
		http.get(`/api/community/albums`).then(res=>{
			if (res.data.status === 'succeed'){
				setData(res.data.data)
                setMode('full')
			} else {
                setMode('short')
			}
		})
	}
    useEffect(()=>{},[mode])
    switch (mode){
        case 'short':
            return(
                <div section="album" className="albumSection section comm">
                    <h2 className="communitySectionHeader waiting" style={{padding:'0'}}>ALBUMS</h2>
                    <div className='albumsCollection'>
                        <Album album={album}/>
                    </div>
                    {
                        total>1?<h5 className="albumExpand" onClick={()=>{requestAlbums()}}>More Albums</h5>:null
                    }
                </div>
            )
        case 'full':
            return(
                <div section="album" className="albumSection section comm">
                    <h2 className="communitySectionHeader waiting" style={{padding:'0'}}>ALBUMS</h2>
                    <div>
                        {data.map(item=>{
                            return <Album album={item}/>
                        })}
                    </div>
                </div>
            )
        case 'loading':
            return(
                <div section="album" className="albumSection section comm">
                    <Loading/>
                </div>
            )
        default:
            return(
                <div section="album" className="albumSection section comm">
                </div>
            )
    }
}