import React,{ useEffect, useRef, useState } from 'react'
import validator from "../../common/validator"
import Loading from "../Loading"
import { useTranslation } from 'react-i18next'
import "../../styles/components/forms/style.css"
import { http } from '../../framework/http'
import CodeForm from './codeForm'
import ContactUsForm from './contactUsForm'
import MemberShipForm from './membershipForm'
import { useHistory } from 'react-router-dom'

export default function CustomForms({classes}){
	const codeForm = useRef()
	const contactForm = useRef()
	const formsLoader = useRef()
	const history = useHistory()
	const { t } = useTranslation()
	const [slogan, setSlogan] = useState({ val: '' })
	const [code, setcode] = useState('');
	const [codes, setCodes] = useState([]);
	const [stats, setStats] = useState(false);
	const [referralError, setReferralError] = useState(false)
	useEffect(()=>{
		http.get('/api/statics/membership-slogan').then(res=>{
			if (res.data.status === 'succeed')
				http.get('/api/codes').then(response=> {
					if (response.data.status === 'succeed'){
						setCodes(response.data.codes.map(rule => rule.value))
						setSlogan(res.data.val)
						if (!stats) setStats(true)
					} else if (response.data.message === 'No codes found'){
						setSlogan(res.data.val)
						if (!stats) setStats(true)
					}
					else history.push('/')
				})
			else history.push('/')
		})
	}, [stats, history])

	const checkRefferalCode = event => {
		event.preventDefault()
		validator(codeForm.current, 'input[name="referralCode"]', 'button[validator="code"]')
		if (codes.includes(code)) {
			setStats('full');
			setReferralError(false)
		}
		else if (stats === 'full') setStats(true)
		
		else setReferralError(true)
	}

	const activateFormsLoader = event =>{
		formsLoader.current.classList.replace('hidden', 'show')
		return false
	}
	if (stats)
	return(
		<div className={ classes }>
			<div className='formsLoader hidden' ref={ formsLoader }>
				<div className='formsLoaderWrapper'>
					<div className="loadingio-spinner-rolling-jr4girhj6eh"><div className="ldio-tp6laxxntc">
					<div></div>
					</div></div>
					<h2>Thank you for waiting.<br />Please don't refresh the website.</h2>
				</div>
			</div>
			<form ref={ codeForm } onSubmit={ activateFormsLoader } className='codeForm' method="POST" action='/api/forms/code'>
				<img alt='Life sports club' className="applogo" src="/media/icons/logo.png" />
				<p className='sammary'>
					{
						slogan.val.split('\n').map((line, i)=>
							<span key={ i }>
								{ i > 0 && <br key={ 'br-' +i }/> }
								{ line }
							</span>) 
					}
				</p>
				<div className={!(stats === 'full') ? 'formWrap inlineForm' : 'formWrap'}>
					{
						(stats !== 'full') ?
							<CodeForm
								checkRefferalCode={checkRefferalCode}
								code={code}
								setCode={setcode}
								referralError={referralError}
							/> : null
					}
					{ 
						(stats === 'full') ?
							<MemberShipForm
								code={code}
								validators={{
									name: ()=> validator(codeForm.current, 'input[name="name"]', 'button[validator="code"]'),
									email: ()=> validator(codeForm.current, 'input[name="email"]', 'button[validator="code"]'),
									phone: ()=> validator(codeForm.current, 'input[name="mobile"]', 'button[validator="code"]'),
									link: ()=> validator(codeForm.current, 'input[name="socialMedia"]', 'button[validator="code"]'),
									address: ()=> validator(codeForm.current, 'input[name="address"]', 'button[validator="code"]'),
									company: ()=> validator(codeForm.current, 'input[name="companyName"]', 'button[validator="code"]'),
									spouseName: ()=> validator(codeForm.current, 'input[name="spouseName"]', 'button[validator="code"]'),
									spouseEmail: ()=> validator(codeForm.current, 'input[name="spouseEmail"]', 'button[validator="code"]'),
									spousePhone: ()=> validator(codeForm.current, 'input[name="spouseMobile"]', 'button[validator="code"]'),
									spouseLink: ()=> validator(codeForm.current, 'input[name="spouseSocialMedia"]', 'button[validator="code"]'),
									all: ()=> validator(codeForm.current, [
										'input[name="name"]',
										'input[name="email"]',
										'input[name="mobile"]',
										'input[name="socialMedia"]',
										'input[name="address"]',
										'input[name="companyName"]',
										'input[name="spouseName"]',
										'input[name="spouseEmail"]',
										'input[name="spouseMobile"]',
										'input[name="spouseSocialMedia"]'
									], null)
								}}
							/>
						:
							<button
								type='button'
								onClick={ checkRefferalCode }
								validator="code"
							>{ t('forms.code.button') }
							</button>
					}
				</div>
			</form>
			<form ref={ contactForm } onSubmit={ activateFormsLoader } className='contactForm' method="POST" action='/api/forms/contact'>
				<ContactUsForm
					validators={{
						name: ()=> validator(contactForm.current, 'input[name="name"]', 'button[validator="check"]'),
						email: ()=> validator(contactForm.current, 'input[name="email"]', 'button[validator="check"]'),
						phone: ()=> validator(contactForm.current, 'input[name="mobile"]', 'button[validator="check"]'),
						dynamic: ()=> validator(contactForm.current, 'input[validate="dynamic"]', 'button[validator="check"]'),
						all: ()=> validator(contactForm.current, ['input[name="name"]', 'input[name="mobile"]', 'input[validate="dynamic"]'], null)
					}}
				/>
			</form>
		</div>
	)
	return <Loading/>
}