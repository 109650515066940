import { Link } from "react-router-dom"
import { useGA } from "../../context/ga"

export default function VideoItem({link, path, title, classes, altPath}){
    const { trackContentView } = useGA()

    if (path.includes('youtube.com/embed')){
        return (
            <Link to={ link } className={ classes } onClick={() => trackContentView('Video, Youtube', title)}>
                <iframe
                    title='life sport club gallery'
                    src={ path }
                    className={ 'banner' }
                    frameborder="0"
                    allow="accelerometer;
                        autoplay; 
                        clipboard-write; 
                        encrypted-media; 
                        gyroscope; 
                        picture-in-picture; 
                    web-share"
                    allowfullscreen
                    onError={ (e)=> e.currentTarget.src = altPath }>
                </iframe>
                <div className="video-details">
                    <h4>{ title }</h4>
                    <img src="/media/icons/full-screen.png" width={ '33px' } height={ '33px' } alt="full screen" />
                </div>
            </Link>
        )
    }
    return (
        <Link to={ '/community' } className={ classes } onClick={() => trackContentView('Video', title)}>
            <video controls className='banner' poster={ path } onError={ (e)=> e.currentTarget.poster = altPath }></video>
        </Link>
    )
}