import { useEffect, useState } from 'react'
import '../../styles/pages/milestones/style.css'
import AboveAll from '../../components/AboveAll'
import TimeLine from '../../components/About/sections/timeline'
import Loading from '../../components/Loading'
import {http} from '../../framework/http'
import { useHistory } from 'react-router-dom'

export default function Milestones(){
	document.title = 'LSC | Featured Events'
    const [stats,setStats] = useState(false)
    const [results,setResults] = useState(null)
	const history = useHistory()
    useEffect(()=>{
		if (!stats){
			document.querySelector('.menuItem[href="/about"]').classList.add('active')
			http.get(`/api/timeline/`).then(res=>{
				if (res.data){
					if (res.data.status === 'succeed'){
						setResults(res.data.data)
						setStats(true)
					} else history.push('/')
				} else history.push('/')
			})
		}
	},[stats, history])
	if (stats) return (
		<div className="TimeLineSection">
			<AboveAll/>
            <div className='timelineSection'>
				<h2 className='timelineSlogan'>LIFE SPORTS CLUB EVENTS</h2>
                <TimeLine events={results}/>
            </div>
		</div>
	)
	else return <Loading/>
}