import { useEffect, useState } from "react"
import Item from "./item.component"
import Loading from "../Loading"
import { http } from "../../framework/http"
import VideoItem from "./videoItem.component"

export default function Album({album}){
    const [data,setData] = useState(null)
    const [mode,setMode] = useState('start')
    function requestImages(){
		setMode('loading')
		http.get(`/api/album/${album.id}/more`).then(res=>{
			if (res.data.status === 'succeed'){
				setData(res.data.data)
                setMode('full')
			} else {
                setMode('short')
			}
		})
	}
    let orders = [0,1,2,3]
    useEffect(()=>{
        if (mode === 'start'){
            http.get(`/api/album/${album.id}`).then(res=>{
                if (res.status !== 404 && res.data.status === 'succeed'){
                    setData(res.data.data)
                    setMode('short')
                } else {
                    setData({images:[],total:0})
                    setMode('short')
                }
            })
        }
    },[mode,album.id])
    switch (mode){
        case 'short': 
            return(
                data.images.length ?
                    <div className="albumInstance">
                        <h4 className="albumName waiting">{ album.name.split('\n').map((line, i)=> <div key={i}> { i > 0 && <br/> }<span>{ line }</span> </div>) }</h4>
                        <div className="albumGroup default">
                            {orders.map(i=>{
                                let item = data.images[i]
                                return (item !== undefined)
                                ?(item.type === 'video')
                                    ?<VideoItem link={`/player/${item.id}`} key={i} path={item.path} title={item.title} classes={'album waiting'} altPath={'/media/images/landing-album.jpg'} />
                                    :<Item link={`/community`} title={album.owner} iid={i} key={i} path={item.path} classes={`album waiting notALink popUp ${album.owner + i}`} alt={'album'} altPath={'/media/images/landing-album.jpg'} />
                                :null
                            })}
                            {((data.total - data.images.length) > 0)
                                ?<h5 className="sectionExpand" onClick={()=>{requestImages()}}>{data.total - data.images.length}+ Photos</h5>
                                :null
                            }
                        </div>
                    </div>
                : null
            )
        case 'full': 
            return(
                <div className="albumInstance">
                    <h4 className="albumName waiting">{ album.name.split('\n').map((line, i)=> <div key={i}> { i > 0 && <br/> }<span>{ line }</span> </div>) }</h4>
                    <div className="albumGroup default">
                        {data.images.map((img,i)=>{
                            return (img.type === 'video')
                            ?<VideoItem link={`/player/${img.id}`} key={i} path={img.path} classes={'videos waiting'} altPath={'/media/images/landing-album.jpg'} />
                            :<Item link={`/community`} title={album.owner} iid={i} key={i} path={img.path} classes={`album waiting notALink popUp ${album.owner + i}`} alt={'album'} altPath={'/media/images/landing-album.jpg'} />
                        })}
                    </div>
                </div>
            )
        case 'loading':
            return(
                <Loading/>
            )
        case 'start':
            return(
                <Loading/>
            )
        default:
            return(
                <></>
            )
    }
}