import { Link } from "react-router-dom";
import { goTo } from "../../common/smothServe"

export default function SubNav({parent,disableScrolling}){
    switch (parent){
        case 'community':
            return (
                <div className="communitySubNav">
                    <h3 onClick={()=>{disableScrolling();goTo('.section[section="events"]',false)}}>Events</h3>
                    <h3 onClick={()=>{disableScrolling();goTo('.section[section="blogs"]',false)}}>Blogs</h3>
                    <h3 onClick={()=>{disableScrolling();goTo('.section[section="album"]',false)}}>Albums</h3>
                    <h3 onClick={()=>{disableScrolling();goTo('.section[section="videos"]',false)}}>Videos</h3>
                </div>
            )
        case 'about':
            return (
                <div className="aboutSubNav">
                    <h3 onClick={()=>{disableScrolling();goTo('.section[section="story"]',false)}}>Our Story</h3>
                    <h3 onClick={()=>{disableScrolling();goTo('.section[section="vision"]',false)}}>Our Values</h3>
                    <h3 onClick={()=>{disableScrolling();goTo('.section[section="board"]',false)}}>Board Members</h3>
					<h3><Link style={{textDecoration:'none',color:'white'}} to={'/featured-events'}>Events</Link></h3>
				</div>
            )
        default:
            return (
                <div className="defaultSubNav">
					<h3><Link style={{textDecoration:'none',color:'white'}} to={'/community#events'}>Events</Link></h3>
					<h3><Link style={{textDecoration:'none',color:'white'}} to={'/community#blogs'}>Blogs</Link></h3>
					<h3><Link style={{textDecoration:'none',color:'white'}} to={'/community#album'}>Albums</Link></h3>
					<h3><Link style={{textDecoration:'none',color:'white'}} to={'/community#videos'}>Videos</Link></h3>
                </div>
            )
    }
}