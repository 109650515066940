import '../../styles/pages/membership/style.css'
import AboveAll from '../../components/AboveAll'
import CustomForms from '../../components/Forms'
import Footer from '../../components/Footer'

export default function Membership(){
	document.title = 'LSC | Membership'

	return (
		<>
			<AboveAll/>
			<div className="daApp">
				<div className='formsWrapper'>
					<CustomForms classes={'forms'}/>
				</div>
				<Footer/>
			</div>
		</>
	)
}