import { useTranslation } from 'react-i18next'

export default function CodeForm({ code, setCode, referralError }) {
    const {t} = useTranslation()
    return (
        <div className='codeInputAround'>
            <label className='inputAround' aria-label="Referral code">
                {referralError ? <p style={{ color: 'red', textAlign: 'left', width: '100%' }}>invalid code</p> : null}
                <input
                    value={ code }
                    onChange={ (e) => setCode(e.target.value) }
                    // onChange={ checkRefferalCode }
                    // onInput={ checkRefferalCode }
                    maxLength="64"
                    minLength="3"
                    required
                    name="referralCode"
                    placeholder={ t('forms.code.placeHolders.code') }
                    className="refinput"
                    style={ referralError ? { border: '1px solid red' } : {} }
                />
            </label>
        </div>
    )
}
