export default function AcademyCoach({coach}){
    return(
        <div className="academy-coach">
            <img className="coach-photo" src={coach.image} alt="coach" onError={e=> e.currentTarget.src = '/media/images/academies.jpg'}/>
            <div>
                <h2 className="coach-name">{ coach.name.toUpperCase().split('\n').map((line, i)=> <> { i > 0 && <br/> }<span>{ line }</span> </>) }</h2>
                <p className="coach-summary">{ coach.summary.split('\n').map((line, i)=> <> { i > 0 && <br/> }<span>{ line }</span> </>) }</p>
            </div>
        </div>
    )
}