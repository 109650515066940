import { useTranslation } from 'react-i18next'
export default function Partners(){
    const {t} = useTranslation()
    return (
        <div section="board" id="board-members" className="boardSection section about">
            <h2 className="aboutSectionHeader waiting">{t('about.sections.partners.headline')}</h2>
            {/* <div className="board-member waiting" id="first">
                <div>
                    <img alt="board member" className="image" src="/media/images/board-member1.jpg"/>
                </div>
                <h3 className="name">{t('about.sections.board.members.member1.name')}</h3>
                <p className="summary">{t('about.sections.board.members.member1.sammary')}</p>
            </div> */}
            <div className="board-member waiting"  id="second">
                <div>
                    <img src="/media/images/team2.png" className="image" alt="partners"/>
                </div>
                <h3 className="name">{t('about.sections.partners.partners.partner1.name')}</h3>
                <p className="summary">{t('about.sections.partners.partners.partner1.description')}</p>
            </div>
            {/* <div className="board-member waiting"  id="third">
                <div>
                    <img alt="board member" className="image" src="/media/images/board-member3.jpg"/>
                </div>
                <h3 className="name">{t('about.sections.board.members.member3.name')}</h3>
                <p className="summary">{t('about.sections.board.members.member3.sammary')}</p>
            </div> */}
            <div className="board-member waiting"  id="forth">
                <div>
                    <img alt="partners" className="image" src="/media/images/MVLogo-Yellow.svg"/>
                </div>
                <h3 className="name">{t('about.sections.partners.partners.partner2.name')}</h3>
                <p className="summary">{t('about.sections.partners.partners.partner2.description')}</p>
            </div>
            {/* <div className="board-member waiting"  id="fifth">
                <div>
                    <img alt="board member" className="image" src="/media/images/board-member5.jpg"/>
                </div>
                <h3 className="name">{t('about.sections.board.members.member5.name')}</h3>
                <p className="summary">{t('about.sections.board.members.member5.sammary')}</p>
            </div> */}
        </div>
    )
}