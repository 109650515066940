import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function ContactUsForm({ validators }){
    const { t } = useTranslation()
    const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState(false)
    const [howDidYouHearAboutUsOther, setHowDidYouHearAboutUsOther] = useState('')
    const [whenToCall, setWhenToCall] = useState('')

    const DynamicInput = () => {
        let input = { label: '', name: '' }
        let show = true
        switch(howDidYouHearAboutUs){
            case 'Social media':
                show = false
                break
            case 'Life Club Partnership':
                input = { label: 'Partnership Name', name: 'lifePartner' }
                break
            case 'Life Club Member':
                input = { label: 'Club Member Name', name: 'lifeMember' }
                break
            case 'Life Club Employee':
                input = { label: 'Club Employee Name', name: 'lifeEmployee' }
                break
            case 'Event':
                input = { label: 'Event Name', name: 'lifeEvent' }
                break
            default:
                show = false
                break
        }
        if (show)
            return (
                <label className='inputAround' aria-label={ input.label }>
                    <input
                        onChange={(e) => {
                            validators.dynamic();
                            setHowDidYouHearAboutUsOther(e.target.value)
                        } }
                        maxLength="64"
                        minLength="3"
                        required
                        validate="dynamic"
                        name={ input.name }
                        type={ 'text' }
                        placeholder={ input.label }
                        className="refinput"
                    />
                </label>
            )
        return <></>
    }
    const handleHaveKids = e=> {
        setHowDidYouHearAboutUs(e.currentTarget.value)
    }
    return (<>
        <h1 className="sectionHeader">{t('forms.contactUs.header')}</h1>
        <p style={{color: 'red', width: "70%", textAlign: "left"}}>{ whenToCall === '' || howDidYouHearAboutUs === '' ? "*Please fill all the fields" : '' }</p>
		<div className='inputsFieldsAround'>
            <label className='inputAround' aria-label="User name">
				<input
                    onChange={ validators.name }
                    maxLength="64"
                    minLength="3"
                    required
                    name="name"
                    type={ 'text' }
                    placeholder={ t('forms.contactUs.placeholders.name') }
                    className="refinput"       
                />
			</label>
            <label className='inputAround email' aria-label="Email adrress">
                <input
                    onChange={ validators.email }
                    maxLength="64"
                    minLength="8"
                    name="email"
                    type={ 'email' }
                    placeholder={ t('forms.contactUs.placeholders.email') }
                    className="refinput"
                    required
                />
            </label>
		</div>
        <div className='inputsFieldsAround'>
            <label className='inputAround' aria-label="Mobile number">
                <span className='countryCode'>
                    <img src='/media/icons/egypt.svg' alt='+20'/>
                    +20
                </span>
				<input
                    onChange={ validators.phone }
                    maxLength="11"
                    minLength="10"
                    required
                    name="mobile"
                    type={ 'text' }
                    placeholder={ 'Mobile number' }
                    className="refinput mobile-number"
                />
			</label>
            <label className='dropdownAround' aria-label="How did you hear about us?">
                <select name='howDidYouHearAboutUs' required onChange={ handleHaveKids }>
                    <option disabled selected>How did you hear about us?</option>
                    <option value={'Social Media'}>Social Media</option>
                    <option value={'Life Club Partnership'}>Life Club Partnership</option>
                    <option value={'Life Club Member'}>Life Club Member</option>
                    <option value={'Life Club Employee'}>Life Club Employee</option>
                    <option value={'Event'}>Event</option>
                </select>
            </label>
		</div>
        <div className='inputsFieldsAround'>
            { howDidYouHearAboutUs && <DynamicInput /> }
            <label className='dropdownAround' aria-label="When would you like to receive a call?">
                <select name='whenToReceiveCall' required onChange={ (e) => setWhenToCall(e.currentTarget.value) }>
                    <option disabled selected value={''}>When would you like to receive a call?</option>
                    <option value={'Anytime'}>Anytime</option>
                    <option value={'Week day from 11:00 am to 3:00 pm'}>Week day from 11:00 am to 3:00 pm</option>
                    <option value={'Weekday from 6:00 pm to 9:00 pm'}>Weekday from 6:00 pm to 9:00 pm</option>
                    <option value={'Weekend from 11:00 am to 3:00 pm'}>Weekend from 11:00 am to 3:00 pm</option>
                    <option value={'Weekend from 6:00 pm to 9:00 pm'}>Weekend from 6:00 pm to 9:00 pm</option>
                </select>
            </label>
        </div>
        <button
            disabled={ whenToCall === '' || howDidYouHearAboutUs === '' }
            style={{ opacity: whenToCall === '' || howDidYouHearAboutUs === '' ? '0.5' : '1' }}
            type='submit'
            onClick={ validators.all }
            validator="check"
        >{ t('forms.contactUs.button') }
        </button>
    </>)
}