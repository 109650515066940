let currImg = 1;
let imgcontainer,next,prev,numImg,imagesArray,sloganArray,timer,pointsContainer,slogansContainer

let timeoutID;

const nextBtn = () => {
    currImg++;
    clearTimeout(timeoutID);
    updateImage(timer);
};

const prevBtn = () => {
    currImg--;
    clearTimeout(timeoutID);
    updateImage(timer);
}

const slideTo = (order)=>{
    currImg = order;
    clearTimeout(timeoutID);
    updateImage(timer);
}

const setSlider = (slider,container,nxt,prv,points,slogans,time)=>{
    var s = document.querySelector(slider).parentElement
    imgcontainer = s.querySelector(container)
    slogansContainer = s.querySelector(slogans)
    next = s.querySelector(nxt)
    prev = s.querySelector(prv)
    pointsContainer = s.querySelector(points)
    slogansContainer = s.querySelector(slogans)
    next.addEventListener("click",nextBtn)
    prev.addEventListener("click",prevBtn)
    imagesArray = imgcontainer.querySelectorAll("img")
    sloganArray = slogansContainer.querySelectorAll("h2")
    numImg = imagesArray.length
    timer = time
    updateImage(timer)
}

function updateImage(timer) {
    if (currImg > numImg) {
        currImg = 1;
    } else if (currImg < 1) {
        currImg = numImg;
    }
    imgcontainer.style.transition = `${timer/2}ms`;
    imgcontainer.style.transform = `translateX(-${(currImg - 1) * imagesArray[currImg - 1].offsetWidth}px)`;
    if (sloganArray[currImg[-1]]) slogansContainer.style.transition = `${timer/2}ms`;
    if (sloganArray[currImg[-1]]) slogansContainer.style.transform = `translateX(-${(currImg - 1) * sloganArray[currImg - 1].offsetWidth}px)`;
    pointsContainer.querySelector(`span.current`)?.classList.remove('current')
    pointsContainer.querySelector(`span:nth-child(${currImg})`)?.classList.add('current')
    timeoutID = setTimeout(() => {
        currImg++;
        updateImage(timer);
    }, timer);
}
export {setSlider, slideTo}