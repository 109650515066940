import { useEffect } from "react"

export default function BlogContent({markdown}){
    useEffect(()=>{
        document.querySelector('.blog-content').innerHTML = markdown
    },[markdown])
    return (
        <div className="blog-content">
        </div>
    )
}