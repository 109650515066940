import { useState,useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { http } from '../../framework/http'
import Loading from "../Loading"
import VideoItem from './videoItem.component'

export default function Videos({ previewData, total }){
    const orders = [0,1,2,3,4,5]
    const {t} = useTranslation()
    const [data,setData] = useState(null)
    const [mode,setMode] = useState('short')
    function requestData(e){
		setMode('loading')
		http.get(`/api/community/videos`).then(res=>{
			if (res.data.status === 'succeed'){
				setData(res.data.data)
                setMode('full')
			} else {
                setMode('short')
			}
		})
	}
    useEffect(()=> {}, [mode])
    switch (mode){
        case 'short':
            return(
                <div section="videos" className="videosSection section comm">
                    <h2 className="communitySectionHeader waiting" style={{ padding:'0' }}>{ t('community.videosSectionIntro') }</h2>
                    <div className="videosGroup">
                        { orders.map(order=> {
                            let item = previewData[order]
                            return (item !== undefined)
                            &&<VideoItem
                                key={ order }
                                link={ `/player/${item.id}` }
                                title={ item.title }
                                path={ item.path }
                                classes={ 'videos waiting' }
                                altPath={ '/media/images/landing-album.jpg' }
                            />
                        }) }
                    </div>
                    {
                        total>6?<button className="sectionExpand" onClick={ requestData }>More Videos</button>:null
                    }
                </div>
            )
        case 'full':
            return(
                <div section="videos" className="videosSection section comm">
                    <h2 className="communitySectionHeader waiting" style={{padding:'0'}}>{t('community.videosSectionIntro')}</h2>
                    <div className="videosGroup">
                        {data.map(item=>{
                            return <VideoItem
                                key={ item.id }
                                link={ `/player/${item.id}` }
                                title={ item.title }
                                path={ item.path }
                                altPath={ '/media/images/landing-album.jpg' }
                                classes={ 'videos waiting' }
                            />
                        })}
                    </div>
                </div>
            )
        case 'loading':
            return(
                <div section="videos" className="videosSection section comm">
                    <Loading/>
                </div>
            )
        default:
            return(
                <div section="videos" className="videosSection section comm">
                </div>
            )
    }
}