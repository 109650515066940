export default function Footer(){
    return (
        <div className="footer">
            <img alt='Life sports club' className="applogo" src="/media/icons/logo.png" />
            {/* <h2 style={{color:'white'}}>
                MOHAMED NAGIB AXIS <br/>
                LIFE SPORTS CLUB
            </h2> */}
            <div className="media-in-footer">
                <a className="media" rel="noreferrer" href='https://goo.gl/maps/nHpEkJuzp4r56bPu6' target={'_blank'}>
                    <img alt="linkedin" width={'25px'} height={'25px'} src="/media/icons/LocationIcon.svg"/>
                </a>
                <a className="media" rel="noreferrer" href="https://www.facebook.com/LifeSportsClubEg" target={'_blank'}>
                    <img alt="facebook" width={'25px'} height={'25px'} src="/media/icons/FBIcon.svg"/>
                </a>
                <a className="media" rel="noreferrer" href="https://www.instagram.com/lifesportsclubeg/" target={'_blank'}>
                    <img alt="instagram" width={'25px'} height={'25px'} src="/media/icons/IGIcon.svg"/>
                </a>
                <a className="media" rel="noreferrer" href="https://www.linkedin.com/company/lifesportsclub" style={{marginRight:'10px'}} target={'_blank'}>
                    <img alt="linkedin" width={'25px'} height={'25px'} src="/media/icons/LinkedInIcon.svg"/>
                </a>
            </div>
        </div>
    )
}