import React, { lazy, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getCookie } from './common/cookies'
import { updateResolution } from './common/backgroundResolution'
import './styles/global.style.css'

import NotFound from './components/NotFound'
import Navigation from './components/Navigation'

import About from './pages/About'
import Community from './pages/Community'
import Membership from './pages/Membership'
import Event from './pages/Community/Event'
import Blog from './pages/Community/Blog'
import AllAcademies from './pages/Academies'
import Academy from './pages/Academies/Academy'
import Loading from './components/Loading'
import VideoPlayer from './pages/player'
import Submitted from './components/Submitted'
import Rejected from './components/Rejected'
import LoadingFade from './components/LoadingFade'
import Milestones from './pages/milestones'
import { GAProvider } from './context/ga';

const LandingPage = lazy(()=> import('./pages/Landing'))
const DetailedModel = lazy(()=> import('./pages/Landing/Detailed'))

function App() {
  const [changed,setChanged] = useState(false)
  var dir = 'ltr'
  const {i18n} = useTranslation()
  if (getCookie('language') === 'ar' && !changed){
    setChanged(true)
    i18n.changeLanguage('ar')
  }
  if (i18n.language === 'ar'){
    dir = 'rtl'
  }
  useEffect(()=>{
    //prevent zooming
    window.addEventListener('wheel',e=>e.ctrlKey&&e.preventDefault(),{"passive": false});
    if (document.querySelector('.menuItem.active')) document.querySelector('.menuItem.active').classList.remove('active')
  },[])
  useEffect(()=>{
    window.addEventListener('resize',e=>{
      updateResolution()
    })
    updateResolution()
  },[])
  return (
    <BrowserRouter>
      <GAProvider>
        <div dir={dir} className='container'>
          <Navigation/>
          <div className='app'>
            <div></div>
            <Suspense fallback={<Loading/>}>
              <LoadingFade />
              <Switch>
                <Route exact path={'/'} component={LandingPage}/>
                <Route path={'/animations/main'} component={DetailedModel}/>
                <Route path={'/animations/gym'} component={DetailedModel}/>
                <Route path={'/about'} component={About}/>
                <Route path={'/featured-events'} component={Milestones}/>
                <Route path={'/community'} component={Community}/>
                <Route path={'/events/:id'} component={Event}/>
                <Route path={'/blogs/:id'} component={Blog}/>
                <Route path={'/membership'} component={Membership}/>
                <Route path={'/academies/all'} component={AllAcademies}/>
                <Route path={'/academies/:sport'} component={Academy}/>
                <Route path={'/player/:id'} component={VideoPlayer}/>
                <Route path={'/application/submitted'} component={Submitted}/>
                <Route path={'/application/rejected'} component={Rejected}/>
                <Route path='*' component={NotFound}/>
              </Switch>
            </Suspense>
          </div>
        </div>
      </GAProvider>
    </BrowserRouter>
  );
}

export default App;
