import React, { useEffect, useState } from 'react'
import '../../../styles/pages/academies/academy/style.css'
import { http } from '../../../framework/http'
import Loading from '../../../components/Loading'
import AcademyHead from '../../../components/Academy/head.component'
import AcademiesGallery from '../../../components/Academies/Gallery'
import AcademyBody from '../../../components/Academy/body.component'
import AboveAll from '../../../components/AboveAll'
import LightFooter from '../../../components/LightFooter'
import AcademyCoach from '../../../components/Academy/coach.component'
import { useHistory } from 'react-router-dom'
import { useGA } from '../../../context/ga'
export default function Academy(props) {
	document.title = `LSC | ${props.match.params.sport} academy`
	const [stats,setStats] = useState(false)
	const [results,setResults] = useState(null)
	const history = useHistory()
	const { trackContentView } = useGA();

	useEffect(()=>{
		http.get(`/api/collections/academies/${props.match.params.sport}`).then(res=>{
			if (res.data){
				if (res.data.status === 'succeed'){
					setResults(res.data)
					setStats(true)
					trackContentView('Academy', res.data.academy.name)
				} else history.push('/')
			} else history.push('/')
		})
	},[props.match.params.sport, history, trackContentView])
	if (stats) return (
		<div className="academyContainer">
			<AboveAll mode={'light'}/>
			<AcademyHead cover={results.academy.poster} name={results.academy.name} />
			<AcademyBody description={results.academy.description} link={ results.academy.registerLink }/>
			{results.coach?<AcademyCoach coach={results.coach}/>:null}
			<div className='galleryHeader'>GALLERY</div>
			<AcademiesGallery images={results.gallery} total={results.total} full={`/api/collections/academies/${results.academy.name}/full`} />
			<LightFooter/>
		</div>
	)
	else return <Loading/>
}