import { Link } from "react-router-dom"
export default function TimeLine({events}){
    let active = true
    function Item({event}){
        if (event) return(
            <Link to={`/events/${event.id}`} style={{textDecoration:'none',color:'white'}}
                className={
                    active === true?
                        "current"
                    :
                        active === null?
                            "next"
                        :
                            active === false?
                                'last'
                            :
                                null
                }
            >
                <div className={active === true?"event active":active===null?"event":"event"}>
                    {
                        active === true?
                            active = null
                        :
                            active === null?
                                active = false
                            : 
                                active === false?
                                    active = undefined
                                :
                                    null
                    }
                    <img src={event.big_cover} alt="cover" className={'timelineCover ' + event.stretch}/>
                    <div className="eventStatic">
                        <img src="/media/icons/point.svg" className="pointImg" alt="event"/>
                        <div id="point"></div>
                        <div className="eventDetails">
                            <h3>{ event.title.toUpperCase().split('\n').map((line, i)=> <i key={i}> { i > 0 && <br/> }<span>{ line }</span> </i>) }</h3>
                            <h4>{ new Date(event.event_date.split('T')[0]).toLocaleDateString('en-US', {month: "long",day: '2-digit',year:"numeric"}).toUpperCase() }</h4>
                        </div>
                    </div>
                </div>
            </Link>
        )
        return <Link to={``} className="previous do-not-return"><div className={"event"}></div></Link>
    }
    function legacyScroller(direction){
        let cur = document.querySelector('a.current')
        let next = document.querySelector('a.next')
        let prv = document.querySelector('a.previous')
        switch (direction){
            case'up':
            if (prv && !prv.classList.contains('do-not-return')) {
                cur.querySelector('.event').classList.remove('active')
                prv.querySelector('.event').classList.add('active')
                cur.classList.replace('current', 'currentToNext')
                prv.classList.replace('previous','previousToCurrent')
                if (next) next.classList.replace('next','nextToLast')
                if (next?.nextElementSibling?.classList.contains('last')) {
                    next.nextElementSibling.style.opacity = '0'
                    setTimeout(()=> {
                        next.nextElementSibling?.classList.remove('last')
                        next.nextElementSibling.style.opacity = '1'
                    }, 500)
                }
                if (prv.previousElementSibling) prv.previousElementSibling.classList.add('toPrevious')
                setTimeout(()=> {
                    cur.classList.replace('currentToNext', 'next')
                    prv.classList.replace('previousToCurrent','current')
                    if (next) next.classList.replace('nextToLast','last')
                    if (prv.previousElementSibling) prv.previousElementSibling.classList.replace('toPrevious', 'previous')
                }, 500)
            }
            break;
            case'down':
            if (next) {
                cur.querySelector('.event').classList.remove('active')
                next.querySelector('.event').classList.add('active')
                cur.classList.replace('current', 'currentToPrevious')
                next.classList.replace('next','nextToCurrent')
                if (prv) prv.classList.remove('previous')
                if (next.nextElementSibling) {
                    next.nextElementSibling.classList.replace('last','lastToNext')
                    if (next.nextElementSibling.nextElementSibling) next.nextElementSibling.nextElementSibling.classList.add('toLast')
                }
                setTimeout(()=> {
                    cur.classList.replace('currentToPrevious', 'previous')
                    next.classList.replace('nextToCurrent', 'current')
                    if (next.nextElementSibling) next.nextElementSibling.classList.replace('lastToNext','next')
                    if (next.nextElementSibling?.nextElementSibling) next.nextElementSibling.nextElementSibling.classList.replace('toLast', 'last')
                }, 500)
            }
            break;
            default:break;
        }
    }
    let tocuhed = false,scrolling = false,start
    function wheelScroller(e){
        if (!scrolling){
            scrolling = true
            setTimeout(()=>{
                if (e.deltaY > 0) legacyScroller('down')
                else legacyScroller('up')
                scrolling = false
            },200)
        }
    }
    function touchScroller(e){
        if (!scrolling){
            scrolling = true
            setTimeout(()=>{
                if (e.deltaY > start) legacyScroller('down')
                else legacyScroller('up')
                scrolling = false
            },200)
        }
    }
    function tocuhDetect(e){
        if (!tocuhed){
            start = e.deltaY
        }
    }
    return(
        <>
            <img id="timelineLine" alt="line" src="/media/icons/timelineline.svg"/>
            <div id="events" onWheel={wheelScroller} onTouchMove={touchScroller} onTouchStart={tocuhDetect}>
                <Item event={null}/>
                {events.map((event,i)=>{
                    return <Item key={i} event={event}/>
                })}
            </div>
        </>
    )
}