import React,{ useEffect, useRef, useState }  from "react"
import "../../styles/pages/community/style.css"
import { goTo } from "../../common/smothServe"
import { sectionManager } from "../../common/about/sectionManager"
import { http } from "../../framework/http"

import AboveAll from '../../components/AboveAll'
import Footer from '../../components/Footer'
import Loading from "../../components/Loading"
import Events from "../../components/Community/events.component"
import Blogs from "../../components/Community/blogs.component"
import Albums from "../../components/Community/albums.component"
import Videos from "../../components/Community/videos.component"
import SubNav from "../../components/SubNavigation"
import { useHistory, useLocation } from "react-router-dom"
import { useGA } from "../../context/ga"

export default function Community() {
	document.title = 'LSC | community'
	const [stats,setStats] = useState(false)
	const [results,setResults] = useState(null)
	const scrollFromUser = useRef(false)
	let location = useLocation()
	const history = useHistory()
	const { trackContentView } = useGA();

	useEffect(()=>{
		if (document.querySelector('.communityContainer')) document.querySelector('.communityContainer').addEventListener('wheel',e=>{scrollFromUser.current = true})
		if (document.querySelector('.communityContainer')) document.querySelector('.communityContainer').addEventListener('touchmove',e=>{scrollFromUser.current = true})
		sectionManager('.comm')
		if (document.querySelector('.communityContainer')) document.querySelector('.communityContainer').addEventListener('scroll',e=>{
			document.querySelectorAll('.section.comm').forEach(section=>{
				if (section.getBoundingClientRect().top < (window.innerHeight / 2)){
					if (document.querySelector('.section.comm.active')&&
					document.querySelector('.communitySectionList')&&
					document.querySelector('.section.comm.active').getAttribute('section')&&
					document.querySelector(`.communitySectionList h2[list-item="${document.querySelector('.section.comm.active').getAttribute('section')}"]`)){
						document.querySelector(`.communitySectionList h2[list-item="${document.querySelector('.section.comm.active').getAttribute('section')}"]`).classList.remove('active')
						document.querySelector('.section.comm.active').classList.remove('active')
					}else if (document.querySelector('.section.comm.active')) document.querySelector('.section.comm.active').classList.remove('active')
					section.classList.add('active')
					if (section.getBoundingClientRect().top - window.innerHeight < 0&&
					section.getAttribute('section')&&
					document.querySelector(`.communitySectionList h2[list-item="${section.getAttribute('section')}"]`))
						document.querySelector(`.communitySectionList h2[list-item="${section.getAttribute('section')}"]`).classList.add('active')
				}
			})
			sectionManager('.comm')
		},[scrollFromUser])
	})
	useEffect(()=>{
		if (stats){
            setTimeout(()=>{
				var list = false
                switch (location.hash){
                    case "#events": scrollFromUser.current = false;goTo('.section[section="events"]',list);break
                    case "#blogs": scrollFromUser.current = false;goTo('.section[section="blogs"]',list);break
                    case "#album": scrollFromUser.current = false;goTo('.section[section="album"]',list);break
                    case "#videos": scrollFromUser.current = false;goTo('.section[section="videos"]',list);break
                    default: return
                }
            },200)
        }
	},[location, stats])
	useEffect(()=>{
		if (!stats){
			http.get(`/api/community/`).then(res=>{
				if (res.data){
					if (res.data.status === 'succeed'){
						setResults(res.data)
						setStats(true)
						trackContentView('Community', document.title)
					} else history.push('/')
				} else history.push('/')
			})
		}
	},[stats, history, trackContentView])
	return (
		<>
			{stats?
				<div className="communityContainer">
					<AboveAll/>
					<div className="sectionsWrapper">
						<SubNav parent={'community'} disableScrolling={()=>scrollFromUser.current = false}/>
						<Events previewData={results.events.rows} total={results.events.total}/>
						<Blogs previewData={results.blogs.rows} total={results.blogs.total}/>
						<Albums album={results.albums.rows} total={results.albums.total}/>
						<Videos previewData={results.videos.rows} total={results.videos.total}/>
						<Footer/>
					</div>
				</div>
			:
				<Loading/>
			}
		</>
	)
}