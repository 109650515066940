function popUp(title,iid){
    let i = document.querySelector(`.popUp.${title}${iid} img`).cloneNode()
    let cover = document.createElement('div')
    let closer = document.createElement('img')
    closer.classList.add('pop-up-closer')
    closer.src = '/media/icons/close.png'
    cover.classList.add('pop-up-cover')
    i.classList.add('pop-up-star')
    cover.addEventListener('click',e=>{
        e.stopPropagation()
        if (document.querySelector('.pop-up-star'))document.querySelector('.pop-up-star').remove()
        if (document.querySelector('.pop-up-cover'))document.querySelector('.pop-up-cover').remove()
        if (document.querySelector('.pop-up-closer'))document.querySelector('.pop-up-closer').remove()
    })
    closer.addEventListener('click',e=>{
        e.stopPropagation()
        if (document.querySelector('.pop-up-star'))document.querySelector('.pop-up-star').remove()
        if (document.querySelector('.pop-up-cover'))document.querySelector('.pop-up-cover').remove()
        if (document.querySelector('.pop-up-closer'))document.querySelector('.pop-up-closer').remove()
    })
    i.addEventListener('click',e=>{
        e.stopPropagation()
    })
    cover.appendChild(i)
    cover.appendChild(closer)
    document.querySelector('.app').appendChild(cover)
}

export {popUp}
