import React, { useEffect, useRef, useState } from "react";
import "../../styles/pages/about/style.css";
import { updateAboutCover } from "../../common/backgroundResolution";
import { sectionManager } from "../../common/about/sectionManager";
import { goTo } from "../../common/smothServe";
import { useLocation } from "react-router-dom";

import AboveAll from "../../components/AboveAll";
import Footer from "../../components/Footer";
import Sections from "../../components/About/sectionList.component";
import SubNav from "../../components/SubNavigation";
import { useGA } from "../../context/ga";
import NotFound from "../../components/NotFound";
import Loading from "../../components/Loading";

export default function About(props) {
  document.title = "LSC | About";

  const scrollFromUser = useRef(false);
  const [stats, setStats] = useState(false);
  const [ratio, setRatio] = useState("0/0");
  const { trackContentView } = useGA();
  let location = useLocation();

  useEffect(() => {
    setStats(true);
    trackContentView("About", document.title);
  }, [trackContentView]);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setRatio(`${window.innerWidth}/16`);
    });
    updateAboutCover();
  }, [ratio]);

  useEffect(() => {
    document.addEventListener("wheel", (e) => {
      scrollFromUser.current = true;
    });
    document.addEventListener("touchmove", (e) => {
      scrollFromUser.current = true;
    });
    sectionManager(".about");
    document.addEventListener(
      "scroll",
      (e) => {
        document.querySelectorAll(".section.about").forEach((section) => {
          if (section.getBoundingClientRect().top < window.innerHeight / 2) {
            if (
              document.querySelector(".section.about.active") &&
              document
                .querySelector(".section.about.active")
                .getAttribute("section")
            ) {
              document
                .querySelector(".section.about.active")
                .classList.remove("active");
            }
            section.classList.add("active");
          }
        });
        sectionManager(".about");
      },
      true
    );
  }, []);

  useEffect(() => {
    if (stats) {
      setTimeout(() => {
        var list = false;
        switch (location.hash) {
          case "#story":
            scrollFromUser.current = false;
            goTo('.section[section="story"]', list);
            break;
          case "#vision":
            scrollFromUser.current = false;
            goTo('.section[section="vision"]', list);
            break;
          case "#board-members":
            scrollFromUser.current = false;
            goTo('.section[section="board"]', list);
            break;
          default:
            return;
        }
      }, 200);
    }
  }, [location, stats]);
  return (
    <>
      {stats ? (
        <>
          {stats === "true" ? (
            <NotFound />
          ) : (
            <div className="customContainer">
              <AboveAll />
              <img
                alt="about"
                className="cover landscape"
                src="/media/images/about169.jpg"
              />
              <div className="coverOverlay"></div>
              <div className="sectionsWrapper">
                <div className="welcomeSection">
                  <p className="coverText">
                    A GAME CHANGER IS COMING <br />
                    TO THE SPORTS AND SOCIAL CLUB <br />
                    LANDSCAPE IN NEW CAIRO.
                  </p>
                  <SubNav
                    parent={"about"}
                    disableScrolling={() => (scrollFromUser.current = false)}
                  />
                </div>
                <Sections />
                <Footer />
              </div>
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
