export function goTo(dest,list){
    document.querySelector(dest).scrollIntoView({
        behavior: 'smooth'
    })
    if (list){
        if (!list.querySelector('h2[list-item="story"]').classList.contains('active')){
			if (list.querySelector('h2.active')){
				list.querySelector('h2.active').classList.remove('active')
			}
			list.querySelector('h2[list-item="story"]').classList.add('active')
		}
    }
}