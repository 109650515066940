import React from 'react'
import { Link } from 'react-router-dom'
import '../../styles/components/notFound/style.css'

export default function Submitted(){
	document.title = 'LSC | Application submitted'
	return (
		<div className='applicationContainer'>
			<h2 style={{ textAlign: 'center' }}>
				Thank you for your interest in Life Sports Club. <br />
				A team member will get in touch with you <br />
				within 24 hours.
			</h2>
			<div className='applicationOptions'>
				<Link to={'/'}>
					<button>Home</button>
				</Link>
				<Link to={'/membership'}>
					<button>Back</button>
				</Link>
			</div>
		</div>
	)
}