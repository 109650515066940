import { useTranslation } from 'react-i18next'
export default function OurVision(){
    const {t} = useTranslation()
    return (
        <div section="vision" className="visionSection section about">
            <h2 className="aboutSectionHeader waiting">{t('about.sections.vision.headline')}</h2>
            <p className="waiting">
                {t('about.sections.vision.line1')}<br/><br/>
            </p>
            <div className="goals">
                <div className="goal g1">
                    <canvas className="progressCircle waiting" width="120" height="120" start="0" end="40" timing="50"></canvas>
                    <img className="waiting" alt="integrity" src="/media/icons/integrity.png"/>
                    <h4 className="waiting">INTEGRITY</h4>
                </div>
                <div className="goal g2">
                    <canvas className="progressCircle waiting" width="120" height="120" start="0" end="55" timing="45"></canvas>
                    <img className="waiting" alt="unity" src="/media/icons/unity.png"/>
                    <h4 className="waiting">UNITY</h4>
                </div>
                <div className="goal g3">
                    <canvas className="progressCircle waiting" width="120" height="120" start="0" end="70" timing="40"></canvas>
                    <img className="waiting" alt="passion" src="/media/icons/passion.png"/>
                    <h4 className="waiting">PASSION</h4>
                </div>
                <div className="goal g4">
                    <canvas className="progressCircle waiting" width="120" height="120" start="0" end="85" timing="35"></canvas>
                    <img className="waiting" alt="joy" src="/media/icons/joy.png"/>
                    <h4 className="waiting">JOY</h4>
                </div>
                <div className="goal g5">
                    <canvas className="progressCircle waiting" width="120" height="120" start="0" end="100" timing="30"></canvas>
                    <img className="waiting" alt="excellence" src="/media/icons/excellence.png"/>
                    <h4 className="waiting">EXCELLENCE</h4>
                </div>
            </div>
            <p className="waiting">
                {t('about.sections.vision.line2')}<br/><br/>
            </p>
        </div>
    )
}