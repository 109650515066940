import { Link } from "react-router-dom"

export default function BlogReadMore({blogs}){
    function RmBlog({link,alt,path,title,altPath,classes}){
        return(
            <Link to={link} className={classes}>
                <img className='banner' src={path} alt={alt} onError={(e)=> e.currentTarget.src = altPath}/>
                <div className='inCardText'>
                    <h4>{
                        title.split('\n').map((line, i)=> <> { i > 0 && <br/> }<span>{ line }</span> </>)
                    }</h4>
                    <h5>Read more...</h5>
                </div>
        </Link>
        )
    }
    return (
        <div className="blog-read-more">
            <h2 className="blog-rm-head">read more</h2>
            <div className="blogs-collection">
                {blogs.map(blog=>{
                    return <RmBlog key={blog.id} link={`/blogs/${blog.id}`} alt={'recommended blog'} path={blog.card} title={blog.title} altPath={'/media/images/landing-album.jpg'} classes={'blog waiting shadow-box'}/>
                })}
            </div>
        </div>
    )
}