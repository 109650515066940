import { useTranslation } from 'react-i18next'
export default function OurStory(){
    const {t} = useTranslation()
    return (
        <div section="story" className="aboutSection section active about">
            <h2 className="aboutSectionHeader waiting" style={{padding:'0'}}>{t('about.sections.story.headline')}</h2>
            <p className="waiting">
                {t('about.sections.story.line1')}<br/><br/>
                {t('about.sections.story.line2')}<br/><br/>
                {t('about.sections.story.line3')}<br/><br/>
            </p>
        </div>
    )
}