import { useState,useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Item from './item.component'
import Loading from "../Loading"
import { http } from '../../framework/http'

export default function Events({ previewData, total }) {
    const orders = [0,1]
    const {t} = useTranslation()
    const [data,setData] = useState(null)
    const [mode,setMode] = useState('short')
    function requestData(){
		setMode('loading')
		http.get(`/api/community/events`).then(res=>{
			if (res.data.status === 'succeed'){
				setData(res.data.data)
                setMode('full')
			} else {
                setMode('short')
			}
		})
	}
    useEffect(()=>{},[mode])
    switch (mode){
        case 'short':
            return (
                <div section="events" className="eventSection section active comm">
                    <h2 className="communitySectionHeader waiting" style={{padding:'0'}}>{t('community.eventsSectionIntro')}</h2>
                    {orders.map(order=>{
                        let item = previewData[order]
                        return (item !== undefined)
                        &&<Item link={`/events/${item.id}`} key={item.id} path={[item.big_card,item.small_card]} classes={'event waiting'} alt={'event'} altPath={'/media/images/noEvent.jpg'} title={false} />
                    })}
                    {
                        total>2?<button className="sectionExpand" onClick={()=>{requestData()}}>More Events</button>:null
                    }
                </div>
            )
        case 'full':
            return (
                <div section="events" className="eventSection section active comm">
                    <h2 className="communitySectionHeader waiting" style={{padding:'0'}}>{t('community.eventsSectionIntro')}</h2>
                    {data.map(item=>{
                        return <Item link={`/events/${item.id}`} key={item.id} path={[item.big_card,item.small_card]} classes={'event waiting'} alt={'event'} altPath={'/media/images/noEvent.jpg'}/>
                    })}
                </div>
            )
        case 'loading':
            return (
                <div section="events" className="eventSection section active comm">
                    <Loading/>
                </div>
            )
        default:
            return (
                <div section="events" className="eventSection section active comm">
                </div>
            )
    }
}