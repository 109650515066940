import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true,
  debug: false,
};

const GAContext = createContext();

export const useGA = () => useContext(GAContext);

export const GAProvider = ({ children }) => {
  const [utmParams, setUtmParams] = useState({});
  const [startTime, setStartTime] = useState(Date.now());
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('GTM-T4NHBZ99');
    ReactPixel.init('1338895409883423', options);

    const searchParams = new URLSearchParams(location.search);
    const utms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    const params = {};

    utms.forEach(utm => {
      const value = searchParams.get(utm);
      if (value) {
        params[utm] = value;
      }
    });

    if (Object.keys(params).length) {
      setUtmParams(params);
      ReactGA.set(params);
    }

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "New visitor",
      ...params,
    });
    ReactPixel.pageView();

    const handleBeforeUnload = () => {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000;
      if (timeSpent > 180) {
        ReactGA.event({
          category: 'User Engagement',
          action: 'Time Spent',
          label: 'Time spent on website',
          value: timeSpent,
          ...utmParams,
        });
        ReactPixel.trackCustom('User Engagement', {
          action: 'Time Spent',
          label: 'Time spent on website',
          value: timeSpent,
          ...utmParams,
        });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [location, utmParams, startTime]);

  const trackEvent = (category, action, label, value) => {
    ReactGA.event({
      category,
      action,
      label,
      value,
      ...utmParams,
    });
    ReactPixel.trackCustom(category, {
      action,
      label,
      value,
      ...utmParams,
    });
  };

  const trackContentView = (contentName) => {
    trackEvent('Content View', 'View', contentName, Date.now());
  };

  const trackLeadGenerated = (name, number, email) => {
    ReactGA.event({
      category: 'Lead',
      action: 'Generated',
      label: 'Lead Form Submitted',
      value: {
        name,
        number,
        email,
        ...utmParams,
      },
    });
    ReactPixel.trackCustom('Lead', {
      action: 'Generated',
      label: 'Lead Form Submitted',
      value: {
        name,
        number,
        email,
        ...utmParams,
      },
    });
  };

  return (
    <GAContext.Provider value={{ ReactGA, utmParams, trackEvent, trackContentView, trackLeadGenerated }}>
      {children}
    </GAContext.Provider>
  );
};
