import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import Loading from "../Loading"

export default function LoadingFade(){
    const loader = useRef()
    const router = useLocation()

    useEffect(()=> {
        loader.current.classList.remove("fadeOut")
        setTimeout(()=> loader.current.classList.add("fadeOut"), 1000)
    }, [router])

    return <Loading ref={loader} />
}
