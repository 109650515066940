import { useEffect, useState } from "react"
import Loading from "../../Loading"
import { http } from "../../../framework/http"
import { popUp } from "../../../common/imagePopUp"
import '../../../styles/components/gallery/style.css'
import { Link } from "react-router-dom"
import { useGA } from "../../../context/ga"

export default function AcademiesGallery({ images, total, full }){
    const [data,setData] = useState()
    const [mode,setMode] = useState('short')
    const { trackContentView } = useGA()

    function requestImages(e){
		setMode('loading')
		http.get(full).then(res=>{
			if (res.data.status === 'succeed'){
				setData(res.data.data)
                setMode('full')
			} else {
                setMode('short')
			}
		})
	}
    function Item({ classes, title, iid, path, alt, altPath }){
        return (
            <div className={ classes } onClick={ ()=>{
                popUp(title, iid) 
                trackContentView('Image, Academy', title)
            }}>
                <img className='banner' src={ path } alt={ alt } onError={ (e)=> e.currentTarget.src = altPath }/>
            </div>
        )
    }
    function VidItem({ link, classes, path, title, altPath }){
        return (
            <Link to={ link } className={ classes } onClick={()=> trackContentView('Video, Academy, Youtube', title)} >
                <iframe
                    title='life sport club gallery'
                    src={ path }
                    className={ 'banner' }
                    frameBorder="0"
                    allow="accelerometer;
                        autoplay; 
                        clipboard-write; 
                        encrypted-media; 
                        gyroscope; 
                        picture-in-picture; 
                    web-share"
                    allowFullScreen
                    onError={ (e)=> e.currentTarget.src = altPath }>
                </iframe>
                <div className="video-details">
                    <h4>{ title }</h4>
                    <img src="/media/icons/full-screen.png" width={ '33px' } height={ '33px' } alt="full screen" />
                </div>
            </Link>
        )
    }
    useEffect(()=>{}, [mode])
    let orders = [0,1,2,3]
    switch (mode){
        case 'short':
            return (
                <div className="albumGroup default academies">
                    {orders.map(i=>{
                        let item = images[i]
                        return (item !== undefined)
                        ?(item.type === 'video')
                        ?<VidItem
                            link={ `/player/${item.id}` }
                            key={ i }
                            title={ item.title }
                            path={ item.path }
                            classes={ 'waiting light' }
                            altPath={ '/media/images/landing-academies.jpg' }
                        />
                        :<Item
                            title={ 'academies' }
                            iid={ i }
                            key={ i }
                            path={ item.path }
                            classes={ `waiting notALink popUp academies${i} light` }
                            alt={ 'academy' }
                            altPath={ '/media/images/landing-academies.jpg' }
                        />
                        :<Item 
                            title={ 'custom' }
                            iid={ i }
                            key={ i }
                            path={ '/media/images/landing-academies.jpg' }
                            classes={ `waiting notALink popUp custom${i} light` }
                            alt={ 'academy' }
                            altPath={ '/media/images/landing-academies.jpg' }
                        />
                    })}
                    {((total - images.length) > 0)
                        ?<h5 className="sectionExpand" onClick={ requestImages }>{ total - images.length }+ Photos</h5>
                        :null
                    }
                </div>
            )
        case 'full':
            return (
                <div className="albumGroup default academies">
                    {data.map(i=>{
                        return (i.type === 'video')
                        ?<VidItem
                            link={ `/player/${i.id}` }
                            title={ i.title }
                            key={ i.id }
                            path={ i.path }
                            classes={ 'waiting light' }
                            altPath={ '/media/images/landing-academies.jpg' }
                        />
                        :<Item 
                            title={ 'academies' }
                            iid={ i.id }
                            key={ i.id }
                            path={ i.path }
                            classes={ `album waiting notALink popUp academies${i.id} light` }
                            alt={ 'academy' }
                            altPath={ '/media/images/landing-academies.jpg' }
                        />
                    })}
                </div>
            )
        case 'loading':
            return <Loading/>
        default: 
            return <></>
    }
}