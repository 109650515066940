import React,{useEffect, useRef, useState} from "react"
import { NavLink, Link, useLocation } from "react-router-dom"
import '../../styles/components/navigation/style.css'

import { useTranslation } from 'react-i18next'

export default function Navigation(props){
    const parent = useRef()
    const about = useRef()
    const miniAbout = useRef()
    const community = useRef()
    const miniCommunity = useRef()
    const location = useLocation()
    const [mode,setMode] = useState('default')
    const [active,setActive] = useState(false)
    const {t, i18n} = useTranslation()
    var inside = false
    function showMenu(){
        parent.current.nextElementSibling.nextElementSibling.nextElementSibling.classList.replace('hide','show')
    }
    function hideMenu(){
        parent.current.nextElementSibling.nextElementSibling.nextElementSibling.classList.replace('show','hide')
    }
    function navHoverMenu(event,isHoverd,isAbout,minimized){
        event.stopPropagation()
        if (isHoverd){
            if (isAbout){
                closeIt(false)
                if (minimized){
                    miniAbout.current.classList.replace('hide','show')
                } else {
                    about.current.classList.replace('hide','show')
                }
            } else {
                closeIt(true)
                if (minimized){
                    miniCommunity.current.classList.replace('hide','show')
                } else {
                    community.current.classList.replace('hide','show')
                }
            }
        } else {
            setTimeout(function(){
                if (!inside){
                    if (isAbout){
                        if (minimized){
                            miniAbout.current.classList.replace('show','hide')
                        } else {
                            about.current.classList.replace('show','hide')
                        }
                    } else {
                        if (minimized){
                            miniCommunity.current.classList.replace('show','hide')
                        } else {
                            community.current.classList.replace('show','hide')
                        }
                    }
                }
            },400)
        }
    }
    function closeIt(isAbout){
        if (isAbout){
            about.current.classList.replace('show','hide')
        } else {
            community.current.classList.replace('show','hide')
        }
    }
    function seeSubs(e){
        e.stopPropagation()
        if (miniAbout.current.previousElementSibling.querySelector('.miniMenuBtn').classList.contains('active')){
            miniAbout.current.previousElementSibling.querySelector('.miniMenuBtn').classList.remove('active')
            miniAbout.current.classList.replace('show','hide')
        } else {
            if (i18n.language === 'ar'){
                miniAbout.current.previousElementSibling.querySelector('.miniMenuBtn').classList.add('invert')
            } else {
                if (miniAbout.current.previousElementSibling.querySelector('.miniMenuBtn').classList.contains('invert')){
                    miniAbout.current.previousElementSibling.querySelector('.miniMenuBtn').classList.remove('invert')
                }
            }
            miniAbout.current.previousElementSibling.querySelector('.miniMenuBtn').classList.add('active')
            miniAbout.current.classList.replace('hide','show')
        }
    }
    function seeSubsC(e){
        e.stopPropagation()
        if (miniCommunity.current.previousElementSibling.querySelector('.miniMenuBtn').classList.contains('active')){
            miniCommunity.current.previousElementSibling.querySelector('.miniMenuBtn').classList.remove('active')
            miniCommunity.current.classList.replace('show','hide')
        } else {
            if (i18n.language === 'ar'){
                miniCommunity.current.previousElementSibling.querySelector('.miniMenuBtn').classList.add('invert')
            } else {
                if (miniCommunity.current.previousElementSibling.querySelector('.miniMenuBtn').classList.contains('invert')){
                    miniCommunity.current.previousElementSibling.querySelector('.miniMenuBtn').classList.remove('invert')
                }
            }
            miniCommunity.current.previousElementSibling.querySelector('.miniMenuBtn').classList.add('active')
            miniCommunity.current.classList.replace('hide','show')
        }
    }
    useEffect(()=>{
        if(location.pathname.includes('academies')) setMode('light')
        else setMode('default')
        let href = location.pathname.split("/")[1]
        if (href.includes('academies')) href = href + '/all'
        if (href.includes('events') || href.includes('blogs')) href = 'community'
        let activeElement = document.querySelector(`.menuItem.mainLink[href="/${href}"]`)
        if (href.includes('membership')) activeElement = document.querySelector(`.menuItem.mediaLink[href="/${href}"]`)
        if (document.querySelector('.menuItem.active')) document.querySelector('.menuItem.active').classList.remove('active')
        if (document.querySelector('.insiderItem.active')) document.querySelector('.insiderItem.active').classList.remove('active')
        setActive(activeElement)
        if (document.querySelector(`.miniMenuBtn.active`)) document.querySelector(`.miniMenuBtn.active`).classList.remove('active')
        if (document.querySelector(`.miniAbout.show`)) document.querySelector(`.miniAbout.show`).classList.replace('show','hide')
        if (document.querySelector(`.miniCommunity.show`)) document.querySelector(`.miniCommunity.show`).classList.replace('show','hide')
        if (document.querySelector('.insiderItem .menuItem.active')) document.querySelector('.insiderItem .menuItem.active').parentElement.classList.add('active')
    },[location])
    useEffect(()=>{
        if (active){
            if (document.querySelector('.menuItem.active')) document.querySelector('.menuItem.active').classList.remove('active')
            if (document.querySelector('.insiderItem.active')) document.querySelector('.insiderItem.active').classList.remove('active')
            active.classList.add('active')
            setActive(false)
        }
    },[active])
    return(
        <>
            <nav ref={parent} className={"navBar " + mode}>
                <Link to={'/'} className="decoratelessLink"><img loading="lazy" alt="Life sports club" className="logo" src={mode==='light'?"/media/icons/logoLight.png":"/media/icons/logo.png"}/></Link>
                <div className="hamburger">
                    <NavLink 
                        className='menuItem mainLink' 
                        onMouseEnter={ (e)=>{
                            inside=true
                            navHoverMenu(e, true, true, false)
                        }}
                        onMouseLeave={ e =>{
                            inside=false
                            navHoverMenu(e, false, true, false)
                        }}
                        to="/about"
                    >
                            { t('about.link')}
                    </NavLink>

                    <NavLink
                        className='menuItem mainLink'
                        onMouseEnter={ e =>{
                            inside=true
                            navHoverMenu(e, true, false, false)
                        }}
                        onMouseLeave={ e =>{
                            inside=false
                            navHoverMenu(e, false, false, false)
                        }}
                        to="/community"
                    >
                        { t('community.link') }
                    </NavLink>
                    <NavLink className='menuItem mainLink academies' to="/academies/all">
                        { t('academies.link') }
                    </NavLink>
                </div>
                <NavLink className={'menuItem mediaLink media'} to="/membership">
                    { t('becomeAmemberLink') }
                </NavLink>
                <a className="media" rel="noreferrer" href='https://goo.gl/maps/nHpEkJuzp4r56bPu6' target={'_blank'}>
                    <img loading="lazy" alt="location" width={'25px'} height={'25px'} src="/media/icons/LocationIcon.svg"/>
                </a>
                <a className="media" rel="noreferrer" href='https://youtube.com/@lifesportsclub8230' target={'_blank'}>
                    <img loading="lazy" alt="youtube" width={'30px'} height={'30px'} src="/media/icons/youtube.svg"/>
                </a>
                <a className="media" rel="noreferrer" href='https://wa.link/30t4zl' target={'_blank'}>
                    <img loading="lazy" alt="whatsapp" width={'30px'} height={'30px'} src="/media/icons/whatsapp.svg"/>
                </a>
                <a className="media" rel="noreferrer" href="https://www.facebook.com/LifeSportsClubEg" target={'_blank'}>
                    <img loading="lazy" alt="facebook" width={'25px'} height={'25px'} src="/media/icons/FBIcon.svg"/>
                </a>
                <a className="media" rel="noreferrer" href="https://www.instagram.com/lifesportsclubeg/" target={'_blank'}>
                    <img loading="lazy" alt="instagram" width={'25px'} height={'25px'} src="/media/icons/IGIcon.svg"/>
                </a>
                <a className="media linkedIn" rel="noreferrer" href="https://www.linkedin.com/company/lifesportsclub" target={'_blank'}>
                    <img loading="lazy" alt="linkedin" width={'25px'} height={'25px'} src="/media/icons/LinkedInIcon.svg"/>
                </a>
                <svg className="btn" onClick={showMenu} fill="#f2f2f2" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px"><path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"/></svg>
            </nav>
            <div onMouseEnter={()=>{inside = true}} onMouseLeave={(e)=>{inside = false;closeIt(true)}} ref={about} className={"aboutMenu hide " + mode}>
                <NavLink className='menuItem' to="/about">{ t('about.link') }</NavLink>
                <Link to={'/about/#story'}>{ t('about.subLinks.about') }</Link>
                <Link to={'/about/#vision'}>{ t('about.subLinks.vision') }</Link>
                <Link to={'/about/#board-members'}>{ t('about.subLinks.board-members') }</Link>
                <Link to={'/featured-events'}>{ t('about.subLinks.timeline') }</Link>
            </div>
            <div onMouseEnter={()=>{inside = true}} onMouseLeave={()=>{inside = false;closeIt(false)}} ref={community} className={"communityMenu hide " + mode}>
                <NavLink className='menuItem' to="/community">{ t('community.link') }</NavLink>
                <Link to={'/community/#events'}>{ t('community.subLinks.events') }</Link>
                <Link to={'/community/#blogs'}>{ t('community.subLinks.blogs') }</Link>
                <Link to={'/community/#album'}>{ t('community.subLinks.album-images') }</Link>
                <Link to={'/community/#videos'}>{ t('community.subLinks.album-videos') }</Link>
            </div>
            <div onClick={hideMenu} className="overlayMenu hide">
                <div className="insideMenu">
                    <img loading="lazy" alt="close" onClick={hideMenu} className="overlayClose" src="/media/icons/close.png"/>
                    <div className="insiderItem" style={{display:'grid',gridAutoFlow:'column',placeItems:"center start",width:'250px'}}>
                        <NavLink 
                            style={{
                                width:'100%',
                                display: "grid",
                                placeItems: "start",
                                gridAutoFlow: 'column'
                            }}
                            onClick={hideMenu}
                            className='menuItem'
                        to="/about">{ t('about.link').toUpperCase() }
                        </NavLink>
                        <code onClick={seeSubs} className="miniMenuBtn" >{'>'}</code>
                    </div>
                    <div className="miniAbout hide" ref={miniAbout}>
                        <Link to={'/about/#story'}>{ t('about.subLinks.about').toUpperCase() }</Link>
                        <Link to={'/about/#vision'}>{ t('about.subLinks.vision').toUpperCase() }</Link>
                        <Link to={'/about/#board-members'}>{ t('about.subLinks.board-members').toUpperCase() }</Link>
                        <Link to={'/featured-events'}>{ t('about.subLinks.timeline').toUpperCase() }</Link>
                    </div>
                    <div className="insiderItem" style={{display:'grid',gridAutoFlow:'column',placeItems:"center start",width:'250px'}}>
                        <NavLink
                            style={{
                                width:'100%',
                                display: "grid",
                                placeItems: "start",
                                gridAutoFlow: 'column'
                            }}
                            onClick={hideMenu}
                            className='menuItem'
                            to="/community">{ t('community.link').toUpperCase() }
                            </NavLink>
                        <code onClick={seeSubsC} className="miniMenuBtn" >{'>'}</code>
                    </div>
                    <div className="miniCommunity hide" ref={miniCommunity}>
                        <Link to={'/community/#events'}>{ t('community.subLinks.events').toUpperCase() }</Link>
                        <Link to={'/community/#blogs'}>{ t('community.subLinks.blogs').toUpperCase() }</Link>
                        <Link to={'/community/#album'}>{ t('community.subLinks.album-images').toUpperCase() }</Link>
                        <Link to={'/community/#videos'}>{ t('community.subLinks.album-videos').toUpperCase() }</Link>
                    </div>
                    <div className="insiderItem" style={{display:'grid',gridAutoFlow:'column',placeItems:"center start",width:'250px'}}>
                    <NavLink 
                        onClick={hideMenu}
                        className='menuItem'
                        to="/academies/all">{ t('academies.link').toUpperCase() }
                    </NavLink>
                    </div>
                    <div className="insiderMediaRow">
                        <a className="insiderMedia" rel="noreferrer" href="https://www.facebook.com/LifeSportsClubEg" target={'_blank'}>
                            <img loading="lazy" alt="facebook" width={'25px'} height={'25px'} src="/media/icons/FBIcon.svg"/>
                        </a>
                        <a className="insiderMedia" rel="noreferrer" href="https://www.instagram.com/lifesportsclubeg/" target={'_blank'}>
                            <img loading="lazy" alt="instagram" width={'30px'} height={'30px'} src="/media/icons/IGIcon.svg"/>
                        </a>
                        <a className="insiderMedia" rel="noreferrer" href="https://www.linkedin.com/company/lifesportsclub" target={'_blank'}>
                            <img loading="lazy" alt="linkedin" width={'25px'} height={'25px'} src="/media/icons/LinkedInIcon.svg"/>
                        </a>
                    </div>
                    <div className="insiderMediaRow">
                        <a className="insiderMedia" rel="noreferrer" href='https://youtube.com/@lifesportsclub8230' target={'_blank'}>
                            <img loading="lazy" alt="youtube" width={'30px'} height={'30px'} src="/media/icons/youtube.svg"/>
                        </a>
                        <a className="insiderMedia" rel="noreferrer" href='https://wa.link/30t4zl' target={'_blank'}>
                            <img loading="lazy" alt="whatsapp" width={'30px'} height={'30px'} src="/media/icons/whatsapp.svg"/>
                        </a>
                        <a className="insiderMedia" rel="noreferrer" href='https://goo.gl/maps/nHpEkJuzp4r56bPu6' target={'_blank'}>
                            <img loading="lazy" alt="location" width={'25px'} height={'25px'} src="/media/icons/LocationIcon.svg"/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}