export const sectionManager = (comm)=>{
    if (document.querySelector(`.section${comm}.active`)){
        var section = document.querySelector(`.section${comm}.active`)
        section.querySelectorAll('.waiting').forEach(elem=>{
            if (elem.getBoundingClientRect().top < window.innerHeight){
                elem.classList.replace('waiting','animated')
                if (elem.classList.contains('progressCircle')){
                    canvasProgress(elem)
                }
            }
        })
    }
}

const canvasProgress = (elem)=>{
    var ctx = elem.getContext('2d');
    var al = 0;
    var start = parseInt(elem.getAttribute('start'));
    var end = parseInt(elem.getAttribute('end'));
    var timing = parseInt(elem.getAttribute('timing'));
    var cw = ctx.canvas.width;
    var ch = ctx.canvas.height;
    var diff;
    function progressSim(){
        diff = ((al / 100) * Math.PI*2*10).toFixed(2);
        ctx.clearRect(0, 0, cw, ch);
        ctx.lineWidth = 3;
        ctx.fillStyle = '#44D62C';
        ctx.strokeStyle = "#44D62C";
        ctx.beginPath();
        ctx.arc(60, 60, 50, start, diff/10+start, false);
        ctx.stroke();
        if(al >= end){
            clearTimeout(sim);
        }
        al++;
    }
    var sim = setInterval(progressSim, timing);
}