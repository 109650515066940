import React, { useEffect, useState } from 'react'
import { http } from '../../../framework/http'
import '../../../styles/pages/community/blog/style.css'
import '../../../styles/pages/community/blog/blog-builder/style.css'
import '../../../styles/components/navigation/sub/style.css'

import NotFound from '../../../components/NotFound'
import Loading from '../../../components/Loading'
import Gallery from '../../../components/Gallery'
import BlogHead from '../../../components/Community/Blog/head.component'
import BlogContent from '../../../components/Community/Blog/content.component'
import BlogReadMore from '../../../components/Community/Blog/readMore.component'
import Footer from '../../../components/Footer'
import SubNav from '../../../components/SubNavigation'
import AboveAll from '../../../components/AboveAll'
import { useHistory } from 'react-router-dom'
import { useGA } from '../../../context/ga'

export default function Blog(props){
	document.title = 'LSC | Blogs'
	const [stats,setStats] = useState(false)
	const [results,setResults] = useState(null)
	const history = useHistory()
	const { trackContentView } = useGA();

	useEffect(()=>{
		setStats(false)
		http.get(`/api/collections/blogs/${props.match.params.id}`).then(res=>{
			if (res.data){
				if (res.data.status === 'succeed'){
					if (res.data.blog){
						setResults({blog:res.data.blog,gallery:res.data.gallery,blogs:res.data.blogs})
						setStats(true)
						trackContentView('Blog', res.data.blog.title)
					} else setStats('true')
				} else history.push('/')
			} else history.push('/')
		})
	},[props.match.params.id, history, trackContentView])
	return (
		<>
			{stats ?
					<>
						{(stats === "true")
							?<NotFound/>
							:<div className='blogContainer'>
								<AboveAll/>
								<SubNav/>
								<BlogHead title={results.blog.title}/>
								<BlogContent markdown={results.blog.markdown}/>
								{
									(results.gallery && results.gallery.length > 0)
										?<Gallery images={results.gallery} link={`/blogs/${results.blog.id}`} alt={results.blog.title}/>
										:null
								}
								{results.blogs.length > 0 ? <BlogReadMore blogs={results.blogs}/>:null}
								<Footer/>
							</div>
						}
					</>
				:
					<Loading/>
				}
		</>
	)
}