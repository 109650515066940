import { useEffect, useRef } from "react"
import { Link, useLocation } from "react-router-dom"

export default function AboveAll({mode}){
    const aboveee = useRef()
    const location = useLocation()
    useEffect(()=>{
        if (location.pathname !== '/') aboveee.current.style.display = 'grid'
        else aboveee.current.style.display = 'none'
    },[location.pathname])
    return (
        <div ref={aboveee} className='aboveAlll' style={{zIndex:'100000'}}>
            <Link to={'/'} style={{
                gridColumn:'1/-1',
                gridRow:'1/-1',
                width:'90px',
                height:'90px',
                top: '0',
                cursor:'pointer',
                zIndex:'10000',
                position:'absolute',
                margin:'0px 20px',
                transform:'translateY(calc(100vh - 210px))'
            }}>
                <img
                    alt='model'
                    src='/media/icons/explore.gif'
                    style={{
                        gridColumn:'1/-1',
                        gridRow:'1/-1',
                        width:'90px',
                        height:'90px',
                        top: '0',
                        cursor:'pointer',
                        zIndex:'10000',
                        position:'absolute',
                        margin:'-20px 20px',
                        placeItems: 'center'
                    }}
                />
                <h4 style={{
                        gridColumn:'1/-1',
                        gridRow:'1/-1',
                        width:'80px',
                        height:'70px',
                        top: '0',
                        cursor:'pointer',
                        zIndex:'10000',
                        position:'absolute',
                        color:mode === 'light'?'black':'white',
                        fontSize:'14px',
                        margin:'40px 25%',
                        lineHeight: '1.1',
                        transform:'translateY(20px)',
                        fontFamily: 'var(--font-bold-italic)',
                        textAlign: 'center'
                    }}
                >EXPLORE <br />THE CLUB</h4>
            </Link>
        </div>
    )
}