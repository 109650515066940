import axios from 'axios'
let baseUrl = window.location.origin

if (window.location.port !== '') baseUrl = window.location.origin.replace(window.location.port,"5000")

const APIRequest = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    timeout: 10000,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

APIRequest.interceptors.response.use(
    (res) => {
        return res;
    }, 
    (error) => {
        return error.response
})

export {APIRequest as http}