import { forwardRef } from "react"

const Loading = forwardRef((_, ref) => {
    return(
        <div ref={ref} style={{gridColumn:'1/-1',gridRow:'1/-1',display:'grid',placeItems:'center',backgroundColor:'black',width:'100vw',height:'100vh',position:'fixed',top:'0',zIndex:'1000000'}}>
            <img alt='loader' src='/media/icons/loader.gif' style={{width: '300px', height: '300px'}}/>
        </div>
    )
  })

export default Loading
